import React, { useState, useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';
import './team-performance.css';

const TeamPerformance = ({ performance, setPerformance }) => {
    const [inputValue, setInputValue] = useState('');
    const [isEditing, setIsEditing] = useState(true);

    useEffect(() => { //NEW: useEffect to sync inputValue with performance prop
        if (performance) {
            setInputValue(performance.replace(/-/g, '')); // Remove hyphens for input
            setIsEditing(false);
        } else {
            setInputValue('');
            setIsEditing(true);
        }
    }, [performance]);

    const handleChange = (e) => {
        setInputValue(e.target.value.toUpperCase());
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (/^[DWL]{1,10}$/i.test(inputValue)) {
                const formattedPerformance = inputValue
                    .toUpperCase().split('').join('-');
                setPerformance(formattedPerformance);
                setIsEditing(false);
            } else {
                alert('Invalid input! Please enter only D, W, or L up to 10 characters.');
            }
        }
    };

    const renderPerformance = () => {
        return performance.split('').map((result, index) => {
            let resultClass = '';
            switch (result) {
                case 'W':
                    resultClass = 'win';
                    break;
                case 'D':
                    resultClass = 'draw';
                    break;
                case 'L':
                    resultClass = 'loss';
                    break;
                case '-':
                    return <span key={index}>-</span>;
                default:
                    break;
            }
            return (
                <span key={index} className={resultClass}>{result}</span>
            );
        });
    };

    return (
        <div className="container">
            <label className="label">Team Performance:</label>
            {isEditing ? (
                <input
                    className="inputField"
                    type="text"
                    value={inputValue}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    placeholder="Enter D, W, or L up to 10 games"
                />
            ) : (
                <div className="resultContainer">
                    <div className="result">{renderPerformance()}</div>
                    <FaEdit className="editButton" onClick={() => setIsEditing(true)} />
                </div>
            )}
        </div>
    );
}

export default TeamPerformance;