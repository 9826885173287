import OpenAI from "openai";
import axios from 'axios';

const generateMatchSummary = async (matchData, isCup, token) => {
  const team1Lineup = Object.values(matchData.team1.positionsData).map(player => `${player.name}: Rating (${player.overall}) - ${player.currentPosition.name}`).join('\n');
  const team2Lineup = Object.values(matchData.team2.positionsData).map(player => `${player.name}: Rating (${player.overall}) - ${player.currentPosition.name}`).join('\n');

  const organizeGoals = (goals) => {
    const goalsByTeam = goals.reduce((acc, goal) => {
      if (!acc[goal.team]) {
        acc[goal.team] = {};
      }
      if (!acc[goal.team][goal.name]) {
        acc[goal.team][goal.name] = [];
      }
      acc[goal.team][goal.name].push({
        minute: goal.minute,
        assister: goal.assister
      });
      return acc;
    }, {});

    return Object.entries(goalsByTeam).map(([team, players]) => {
      return `${team}:\n${Object.entries(players).map(([player, goals]) => {
        return `${player} at minute ${goals.map(goal => {
          let goalDescription = `${goal.minute}`;
          if (goal.assister) {
            goalDescription += ` (assist by ${goal.assister})`;
          }
          if (typeof goal.minute === 'string' && (goal.minute.includes('P') || goal.minute.includes('o.g.'))) {
            goalDescription += ` (No assist)`;
          }
          return goalDescription;
        }).join(', ')}`;
      }).join('\n')}`;
    }).join('\n\n');
  };

  const team1Goals = organizeGoals(matchData.goalScorers.filter(goal => goal.team === matchData.team1.displayName));
  const team2Goals = organizeGoals(matchData.goalScorers.filter(goal => goal.team === matchData.team2.displayName));

  let penaltyDetails = '';
  if (matchData.penalties) {
    const firstPenaltyTeam = matchData.penalties.penaltyLog[0].team;
    const secondPenaltyTeam = matchData.penalties.penaltyLog[1].team;
    penaltyDetails = matchData.penalties.penaltyLog.map((penalty, index) => {
      return `${penalty.team}: ${penalty.name} ${penalty.scored ? 'scored' : 'missed'} for ${index % 2 === 0 ? firstPenaltyTeam : secondPenaltyTeam}`;
    }).join('\n');
  }

  let apiKey = '';
  try {
    const url = `https://footballcustomformation.com/php_backend/web_server.php/FIFAFormationCreator/getKey`;
    const response = await axios.post(url, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      withCredentials: true
    });
    apiKey = response.data.apiKey;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('User must be logged in to access the API key');
      alert('You must be logged in to access the API key');
    } else {
      console.error('Error fetching API key:', error.response);
    }
  }
  const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });
  const prompt = `
    You are a football commentator. Given the following match data in a ${isCup ? 'cup' : 'league'} match, generate a detailed match summary:
  Team 1: ${matchData.team1.displayName}
  Captain: ${matchData.team1.captain.name}
  Formation: ${matchData.team1.dropFormation}
  Lineup:
  ${team1Lineup}
  Team 2: ${matchData.team2.displayName}
  Formation: ${matchData.team2.dropFormation}
  Captain: ${matchData.team2.captain.name}
  Lineup:
  ${team2Lineup}
  Score: ${matchData.team1Goals} - ${matchData.team2Goals}
  ${matchData.team1Goals > matchData.team2Goals ? 'Team 1 Wins' : matchData.team2Goals > matchData.team1Goals ? 'Team 2 Wins' : 'Team 1 and Team 2 Draw'}
  Goals: 
  ${team1Goals || `No goals scored by ${matchData.team1.displayName}.`}
  ${team2Goals || `No goals scored by ${matchData.team2.displayName}.`}
  Yellow Cards:
  ${matchData.yellowCards.length > 0 ? matchData.yellowCards.map(card => `${card.team}: ${card.name} at minute ${card.minute} for fouling ${card.fouledPlayer}`).join('\n') : 'No yellow cards.'} 
  Red Cards:
  ${matchData.redCards.length > 0 ? matchData.redCards.map(card => `${card.team}: ${card.name} at minute ${card.minute} for fouling ${card.fouledPlayer}`).join('\n') : 'No red cards.'}
  Substitutions:
  Team 1:
  ${matchData.substitutions.team1.length > 0 ? matchData.substitutions.team1.map(sub => `${sub.minute}: ${sub.substitutedPlayer} out, ${sub.substitute} in`).join('\n') : 'No substitutions.'}
  Team 2:
  ${matchData.substitutions.team2.length > 0 ? matchData.substitutions.team2.map(sub => `${sub.minute}: ${sub.substitutedPlayer} out, ${sub.substitute} in`).join('\n') : 'No substitutions.'}
  ${matchData.penalties ? `Final Penalty Score - ${matchData.team1.displayName}: ${matchData.penalties.team1Score}, ${matchData.team2.displayName}: ${matchData.penalties.team2Score}\nPenalty Log:\n${penaltyDetails}` : ''}
  Provide the summary in the style of a professional football match report, including key events, player performances, and overall impressions. 
  Include these stats for each match at the end: Total shots, shots on target, and possession percentage. Choose your player of the match.
  `;

  //console.log('Prompt:', prompt);
  if (prompt.includes('undefined')) {
    console.error('Undefined prompt, try again!');
    return '';
  }
  const response = await openai.chat.completions.create({
    model: "gpt-4o-mini",
    messages: [{ role: "system", content: prompt }]
  });

  const chatContent = response.choices[0].message.content;
  //console.log('ChatGPT response:', chatContent);
  return chatContent;
}

const generateMatchPrediction = async (teams, headToHead, token) => {
  const homeTeam = teams[0];
  const awayTeam = teams[1];

  let apiKey = '';
  try {
    const url = `https://footballcustomformation.com/php_backend/web_server.php/FIFAFormationCreator/getKey`;
    const response = await axios.post(url, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      withCredentials: true
    });
    apiKey = response.data.apiKey;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('User must be logged in to access the API key');
      alert('You must be logged in to access the API key');
    } else {
      console.error('Error fetching API key:', error.response);
    }
  }
  const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });

  const formatPlayers = (players) => {
    return players.map(player => {
      let playerInfo = `${player.name} (Overall: ${player.overall}, Position: ${player.currentPosition.name}, Goals: ${player.goals}, Assists: ${player.assists}, Form: ${player.form})`;
      if (player.currentPosition.name.includes('GK') || player.currentPosition.name.includes('B')) {
        playerInfo += `, Clean Sheets: ${player.cleanSheets}`;
      }
      return playerInfo;
    }).join('\n');
  }

  const prompt = `
    You are a football analyst. Given the detailed data about two teams, predict the likely result of the match including a likely score, the percentage chance of each team winning, 
    and the percentage chance of a draw. Make sure to surround the likely score, winning percentage, and draw percentage with ** for bold.
    
    **Home Team: ${homeTeam.displayName}**
    - **Formation:** ${homeTeam.dropFormation}
    - **Captain:** ${homeTeam.captain.name}
    ${homeTeam.homeStadium && `- **Stadium:** ${homeTeam.homeStadium}`}
    - **Recent Performance:** ${homeTeam.performance} (W-D-L format)
    - **Starting 11:**
    ${formatPlayers(Object.values(homeTeam.positionsData))}
    - **Bench:**
    ${homeTeam.bench.map(player => `${player.name} (Overall: ${player.overall})`).join('\n')}

    **Away Team: ${awayTeam.displayName}**
    - **Formation:** ${awayTeam.dropFormation}
    - **Captain:** ${awayTeam.captain.name}
    - **Recent Performance:** ${awayTeam.performance} (W-D-L format)
    - **Starting 11:**
    ${formatPlayers(Object.values(awayTeam.positionsData))}
    - **Bench:**
    ${awayTeam.bench.map(player => `${player.name} (Overall: ${player.overall})`).join('\n')}
    
    **Head-to-Head Record:** ${homeTeam.displayName} vs ${awayTeam.displayName} (Home Wins | Draws | Away Wins): ${headToHead.wins} | ${headToHead.draws} | ${headToHead.losses}
    
    Consider the provided information and predict the following:
    - **Likely Score:** 
    - **Home Team Win Percentage:** 
    - **Away Team Win Percentage:** 
    - **Draw Percentage:**   
    `;

  console.log('Prediction prompt:', prompt);

  if (window.confirm('Does the prompt look okay?')) {
    const response = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: [{ role: "system", content: prompt }]
    });

    const chatContent = response.choices[0].message.content;
    return chatContent;
  }
  return '';
};

export { generateMatchSummary, generateMatchPrediction };