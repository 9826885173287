import React, { useState } from 'react';
import Ball from '../resources/soccerball.png';
import Assist from '../resources/assist_symbol.png';
import YellowCard from '../resources/yellow_card.png';
import RedCard from '../resources/red_card.png';
import './MatchResultPopup.css';

const ManySimulationsPopup = ({ onSimulate, onClose, results, goBack }) => {
    const [numSimulations, setNumSimulations] = useState(1);
    const assist = <img src={Assist} alt="Player Assist" style={{ width: '20px', verticalAlign: 'bottom' }} />;
    const ball = <img src={Ball} alt="Soccer Ball" style={{ width: '20px', verticalAlign: 'bottom' }} />;
    const yellowCard = <img src={YellowCard} alt="Yellow Card" style={{ width: '20px', verticalAlign: 'bottom' }} />;
    const redCard = <img src={RedCard} alt="Red Card" style={{ width: '20px', verticalAlign: 'bottom' }} />;
    const [isLoading, setIsLoading] = useState(false);

    const handleSimulate = () => {
        setIsLoading(true); // Start loading
        const num = Math.min(Math.max(parseInt(numSimulations), 1), 10000);
        setTimeout(() => {
            onSimulate(num).finally(() => {
                setIsLoading(false); // End loading
            });
        }, 100); // Introduce a small delay to ensure loading state is set
    };

    const parseMinute = (minute) => {
        const minuteStr = minute.toString().replace('(P)', '');

        if (minuteStr.includes('+')) {
            const [regular, stoppage] = minuteStr.split('+').map(Number);
            return regular + stoppage / 100;
        }
        return Number(minuteStr);
    }

    const calculatePercentage = (count, total) => {
        const width = ((count / total) * 100).toFixed(1);
        return width;
    }
    let totalNumber = 0;

    if (results) {
        totalNumber = results.team1Wins + results.team2Wins + results.draws;
    }

    const calculateMinWidth = (text) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = 'bold 14px Arial';
        return context.measureText(text).width + 20; // Add some padding
    };

    const renderOneSidedScores = () => {
        //Group goals by scorer
        return results.mostOneSidedScores.map((score, index) => {
            const scorers = score.goalScorers.reduce((acc, scorer) => {
                if (!acc[scorer.name]) {
                    acc[scorer.name] = [];
                }
                acc[scorer.name].push(scorer.minute);
                return acc;
            }, {});
            //Sort grouped goals by minute
            const sortedScorers = Object.keys(scorers).map(name => ({
                name,
                team: score.goalScorers.find(scorer => scorer.name === name).team,
                minutes: scorers[name].sort((a, b) => parseMinute(a) - parseMinute(b))
            }));

            return (
                <div key={index}>
                    <h3>{score.scoreline}</h3>
                    <div>
                        {sortedScorers.map((scorer, scorerIndex) => (
                            <span key={scorer.name} className={scorer.team === results.team1.displayName ? 'scorer-team1' : 'scorer-team2'}>
                                {scorer.name}: {scorer.minutes.join(', ')}'
                                {scorerIndex < sortedScorers.length - 1 ? ', ' : ''}
                            </span>
                        ))}
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="popup-container large">
            <div className="popup-content many">
                <button className="close-button" onClick={onClose}>X</button>
                <button className="back-button" onClick={goBack}>Back</button>
                <h2>Simulate Matches</h2>
                <input
                    type="number"
                    value={numSimulations}
                    onChange={(e) => setNumSimulations(e.target.value)}
                    min="1"
                    max="10000"
                />
                <button className="simulate-button" onClick={handleSimulate}>Simulate</button>
                {isLoading ? (
                    <div className="loading-container">
                        <div className="loading-bar">
                            <div className="loading-progress"></div>
                        </div>
                        <p>Loading...</p>
                    </div>
                ) : (
                    results && (
                        <div className="results">
                            <div className="result-bar-container">
                                <div className="result-bar">
                                    <div
                                        className="result-segment team1-wins"
                                        style={{
                                            width: `${calculatePercentage(results.team1Wins, totalNumber)}%`,
                                            minWidth: `${calculateMinWidth(results.team1.displayName)}px`
                                        }}
                                    >
                                        <div className="result-label" style={{ minWidth: `${calculateMinWidth(results.team1.displayName)}px` }}>{results.team1.displayName}</div>
                                        <div className="result-percentage">{calculatePercentage(results.team1Wins, totalNumber)}%</div>
                                    </div>
                                    <div
                                        className="result-segment draws"
                                        style={{
                                            width: `${calculatePercentage(results.draws, totalNumber)}%`,
                                            minWidth: `${calculateMinWidth('Draw')}px`
                                        }}
                                    >
                                        <div className="result-label" style={{ minWidth: `${calculateMinWidth('Draw')}px` }}>Draw</div>
                                        <div className="result-percentage">{calculatePercentage(results.draws, totalNumber)}%</div>
                                    </div>
                                    <div
                                        className="result-segment team2-wins"
                                        style={{
                                            width: `${calculatePercentage(results.team2Wins, totalNumber)}%`,
                                            minWidth: `${calculateMinWidth(results.team2.displayName)}px`
                                        }}
                                    >
                                        <div className="result-label" style={{ minWidth: `${calculateMinWidth(results.team2.displayName)}px` }}>{results.team2.displayName}</div>
                                        <div className="result-percentage">{calculatePercentage(results.team2Wins, totalNumber)}%</div>
                                    </div>
                                </div>
                            </div>
                            <div className="result">
                                <h3>Top Scorers {ball}</h3>
                                <div className="scorers-box">
                                    {results.playerGoalCountsArray.map(player => (
                                        <p key={player.name}><strong>{player.name}</strong>: {player.goals} {ball}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="result">
                                <h3>Top Assisters {assist}</h3>
                                <div className="scorers-box">
                                    {results.playerAssistCountsArray.map(player => (
                                        <p key={player.name}><strong>{player.name}</strong>: {player.assists} {assist}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="result">
                                <h3>Most Yellow Cards {yellowCard}</h3>
                                <div className="scorers-box">
                                    {results.yellowCardCountsArray.map(player => (
                                        <p key={player.name}><strong>{player.name}</strong>: {player.yellowCards} {yellowCard}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="result">
                                <h3>Most Red Cards {redCard}</h3>
                                <div className="scorers-box">
                                    {results.redCardCountsArray.map(player => (
                                        <p key={player.name}><strong>{player.name}</strong>: {player.redCards} {redCard}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="result">
                                <h3>Top 5 Scorelines</h3>
                                {results.topScorelines.map(scoreline => (
                                    <p key={scoreline.scoreline}><strong>{scoreline.scoreline}</strong>: {scoreline.count} times</p>
                                ))}
                            </div>
                            <div className="result">
                                <h3>Most One-sided Scores</h3>
                                {renderOneSidedScores()}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ManySimulationsPopup;