import React, { useState } from 'react';
import './DeleteForm.css';
import TrashCan from './resources/trashcan.svg'

const DeleteTeamForm = ({ savedFormations, deleteTeam }) => {
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const handleDeleteClick = () => {
        if (selectedTeam) {
            //console.log(`${selectedTeam.displayName} will be deleted`);
            setShowConfirmationModal(true);
        } else {
            alert('Please select a team to delete');
        }
    };

    const handleTeamSelection = (e) => {
        const selectedTeamName = e.target.value;
        if (selectedTeamName === '') {
            setSelectedTeam(null);
        } else {
            // Find the selected team based on the value (displayName)
            const team = savedFormations.find(team => team.displayName === selectedTeamName);
            console.log(`Selected team: ${selectedTeamName}. Team Id: ${team.teamId}`);
            setSelectedTeam(team);
        }
    }

    const confirmDelete = () => {
        console.log(`Selected team: ${selectedTeam.displayName}. Team Id: ${selectedTeam.teamId}`);
        deleteTeam(selectedTeam.teamId);
        setShowConfirmationModal(false);
        setSelectedTeam(null); 
    }

    return (
        <div>
            <button onClick={() => setShowDropdown(!showDropdown)} className="delete-button" style={{ marginBottom: '10px', marginTop: '10px' }}>
                {showDropdown ? 'Hide' : 'Delete Team'}
            </button>
            {showDropdown && (
                <div>  
                    <select
                        className="select-dropdown"
                        value={selectedTeam ? selectedTeam.displayName : ''}
                        onChange={handleTeamSelection}
                    >
                        <option value="">Select a team to delete</option>
                        {savedFormations.length > 0 ? (
                            savedFormations.map((team, index) => (
                                <option key={index} value={team.displayName}>
                                    {team.displayName}: {team.dropFormation}
                                </option>
                            ))
                        ) : (
                            <option disabled>No teams loaded</option>
                        )}
                    </select>
                    <button onClick={handleDeleteClick} className="delete-button">
                        <img src={TrashCan} alt="Delete" style={{ height: '20px' }} />
                        DELETE
                    </button>
                </div>
            )}

            {showConfirmationModal && (
                <div className="modal-backdrop">
                    <div className="modal-content">
                        <p>Are you sure you want to delete {selectedTeam.displayName}?</p>
                        <button onClick={confirmDelete}>Confirm</button>
                        <button onClick={() => setShowConfirmationModal(false)} style={{marginTop: '5px'}}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeleteTeamForm;