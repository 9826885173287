import React, { useState } from 'react';
import axios from 'axios';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import './paypal-service.css';

function PaypalService({ token, onSubscriptionSuccess }) {
    const initialOptions = {
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        "enable-funding": "paylater,card",
        "disable-funding": "",
        "data-sdk-integration-source": "integrationbuilder_sc",
        vault: "true",
        intent: "subscription",
    }

    const [message, setMessage] = useState('');

    function Message({ content }) {
        return <p>{content}</p>;
    }

    return (
        <div className="paypal-service">
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    style={{
                        shape: "rect",
                        layout: "vertical",
                    }}
                    createSubscription={async (data, actions) => {
                        try {
                            const response = await axios.post("https://footballcustomformation.com/php-backend/paypal_api.php?action=create-subscription", {
                                userAction: "SUBSCRIBE_NOW",
                            }, {
                                headers: {
                                    "Content-Type": "application/json",
                                    'Authorization': `Bearer ${token}`,
                                },
                            });
                            const data = await response.json();
                            if (data?.id) {
                                setMessage('Successful subscription...');
                                return data.id;
                            } else {
                                console.error(
                                    { callback: "createSubscription", serverResponse: data },
                                    JSON.stringify(data, null, 2)
                                );
                                const errorDetail = data?.details?.[0];
                                setMessage(
                                    `Could not initiate PayPal Subscription...<br><br>${errorDetail?.issue || ""
                                    } ${errorDetail?.description || data?.message || ""} ` +
                                    (data?.debug_id ? `(${data.debug_id})` : ""),
                                    { hideButtons: true }
                                );
                            }
                        } catch (error) {
                            console.error(error.response);
                            setMessage(`Could not initiate PayPal Subscription...${error}`);
                        }
                    }}
                    onApprove={(data, actions) => {
                        if (data.subscriptionID) {
                            setMessage(
                                `You have successfully subscribed to the monthly plan. Your subscription id is: ${data.subscriptionID}`
                            );
                            onSubscriptionSuccess(data.subscriptionID);
                        } else {
                            setMessage(
                                `Failed to activate the subscription: ${data.subscriptionID}`
                            );
                        }
                    }}
                />
            </PayPalScriptProvider>
            <Message content={message} />
        </div>
    );
}

export default PaypalService;