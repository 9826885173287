import cheerio from 'cheerio';
import axios from 'axios';
import unaccent from 'unaccent';

const fetchPlayerData = async (player) => {
    const enhancedUnaccent = (text) => {
        // First, use unaccent to remove most accents
        let normalizedText = unaccent(text);

        // Manually replace any specific characters missed by unaccent
        normalizedText = normalizedText.replace(/ú/g, 'u');

        return normalizedText;
    };

    const formatNation = (country) => {
        let formattedCountry = country;
        if (country.includes('(')) {
            const nationMatch = country.match(/\(([^)]+)\)/);
            if (nationMatch && nationMatch[1]) {
                return formatNation(nationMatch[1].trim());
            }
        }
        if (country === 'korea republic') formattedCountry = 'south-korea';
        if (country === 'korea dpr') formattedCountry = 'north-korea';
        if (country === 'china pr') formattedCountry = 'china';
        if (country === 'united states') formattedCountry = 'usa';
        if (country === "côte d'ivoire") formattedCountry = 'ivory-coast';
        if (country === 'north macedonia') formattedCountry = 'fyr-macedonia';
        if (country === 'united arab emirates') formattedCountry = 'uae';

        if (formattedCountry.includes('saint')) {
            console.log(`${country} has saint`);
            formattedCountry = country.replace(/saint/gi, 'st');
        }
        if (formattedCountry.includes(' ')) {
            formattedCountry = formattedCountry.replace(/\s+/g, '-');
        }
        console.log('Formatted country', formattedCountry);
        return formattedCountry;
    }

    const getAsianName = (fullName) => {
        const nameParts = fullName.split(' ');
        const lastName = nameParts[0];
        const firstName = nameParts.slice(1).join('-');

        return { firstName, lastName };
    }

    //const baseUrl = 'https://www.footballcustomformation.com/php_backend/web_server.php';]
    const baseUrl = 'http://localhost:3001/';
    let nationality = formatNation(player.nationality.toLowerCase());
    let shortName = enhancedUnaccent(player.name.toLowerCase());
    if (shortName.endsWith('jr.')) {
        shortName = shortName.replace('jr.', 'junior');
    }
    console.log('Short name', shortName);
    let firstName, lastName;
    if (nationality === 'south-korea' || nationality === 'china') {
        const names = getAsianName(shortName);
        console.log('Asian names', names);
        firstName = names.firstName;
        lastName = names.lastName;
    } else if (nationality === 'japan') {
        try {
            const response = await axios.get(`${baseUrl}convert-name/${player.fullName}/${shortName}`);
            console.log('Kanji response:', response.data);
        } catch (error) {
            console.error('Error getting kanji conversion:', error);
            return null;
        }
    } else {
        let longName = enhancedUnaccent(player.fullName.toLowerCase()); // Remove accents
        //console.log('Long name', longName);
        let longNameParts = longName.split(' ');

        let lastNameFromShort;
        if (shortName.includes('.')) {
            firstName = longName.split(' ')[0];
            const lastNameParts = shortName.split('. ')[1].split(' ');
            if (lastNameParts.length >= 2) {
                lastNameFromShort = lastNameParts.join('-');
            } else {
                lastNameFromShort = lastNameParts[0]; // Fallback in case there aren't two words
            }
            console.log(`Last name from short: ${lastNameFromShort}`);
        } else if (player.name.includes(' ')) {
            firstName = shortName.split(' ')[0];
            lastNameFromShort = shortName.split(' ')[1];
        } else {
            firstName = shortName;
        }


        //Fix if name is asian
        if (!shortName.includes(' ')) {
            lastName = '';
        } else if (player.fullName.includes(' Al ') || player.fullName.includes(' Al-') || player.fullName.includes('Mohamed') || nationality === 'trinidad-and-tobago') {
            console.log(`${player.name} is probably saudi`);
            firstName = longName.replace(/\s+/g, '-');
        } else if (!longName.endsWith(lastNameFromShort)) {
            lastName = lastNameFromShort;
        } else {
            lastName = longNameParts[longNameParts.length - 1];
        }
    }

    let proxyUrl = `${baseUrl}footystats/players/${nationality}/${firstName}${lastName ? `-${lastName}` : ''}`;
    const results = await fetchAndScrapeData(player, proxyUrl);
    return results;
};

const fetchAndScrapeData = async (player, url) => {
    console.log('Url for player:', url);
    try {
        let response = await axios.get(url);
        let $ = cheerio.load(response.data);    
        const seasonSection = $('.content-feed.ui-wrapper').find('h2.section-title:contains("This Season (2023/2024) & Career Stats")').closest('.content-feed.ui-wrapper');
        const ratingElement = seasonSection.find('.form-box.playerLarge');
        const rating = parseFloat(ratingElement.text().trim());
        console.log(`${player.name} rating: ${rating}`);

        let form = 'Okay';
        if (rating < 6.5) {
            form = 'Bad';
        } else if (rating >= 6.5 && rating < 7) {
            form = 'Okay';
        } else if (rating >= 7 && rating < 7.5) {
            form = 'Good';
        } else if (rating >= 7.5 && rating < 7.8) {
            form = 'Very Good';
        } else if (rating >= 7.8) {
            form = 'Excellent';
        }

        const headers = [];
        $('.player_season_table .header .col-lg-1, .player_season_table .header .col-lg-4').each((index, element) => {
            headers.push($(element).text().trim());
        });

        const totals = [];
        $('.player_season_table .totals .col-lg-1, .player_season_table .totals .col-lg-4').each((index, element) => {
            totals.push($(element).text().trim());
        });
        console.log(player.name, "totals:", totals);

        /*
        let mostRecentTotalsIndex = -1;
        for (let i = 0; i < totals.length; i++) {
            if (totals[i] === 'Total 2023/2024') {
                mostRecentTotalsIndex = i;
            }
        }
        
        if (mostRecentTotalsIndex === -1) {
            throw new Error("Could not find the totals for the most recent season");
        }*/

        const goalsIndex = headers.indexOf('Goals');
        const goals = goalsIndex !== -1 ? totals[goalsIndex] : 0; 

        let assists = 0, cleanSheets = 0;
        if (!(player.position.includes('B') || player.position.includes('GK'))) {
            const assistsIndex = headers.indexOf('Assists');
            assists = assistsIndex !== -1 ? totals[assistsIndex] : 0;
        } else {
            //console.log('Headers', headers);
            const csIndex = headers.findIndex(header => header.includes('CSClean Sheets'));
            cleanSheets = csIndex !== -1 ? totals[csIndex] : 0;
            // Extract assists from the "Assists & Passing" section
            const assistRow = $('.mt1e.comparison-table-table.w100').find('tr:contains("Assists")');
            if (assistRow.length > 0) {
                const assistValue = assistRow.find('td.item.stat').first().text().trim();
                assists = parseInt(assistValue) || 0;
            }
        }

        const playerResults = { form, goals, assists, cleanSheets };
        console.log('Player results:', playerResults);
        return playerResults;
    } catch (error) {
        console.error('Error getting data from footystats', error);
    }
}

const getPlayerStatsInQueue = async (queue, saveAllChanges) => {
    for (let i = 0; i < queue.length; i++) {
        const player = queue[i];
        const stats = await fetchPlayerData(player);
        if (stats) {
            player.goals = stats.goals;
            player.assists = stats.assists;
            player.cleanSheets = stats.cleanSheets;
            player.form = stats.form;
        }
    };

    if (window.confirm("Would you like to save the player stats in queue?")) {
        queue.forEach(player => {
            saveAllChanges(player);
        })
    }
}

const savePlayerStats = async (players) => {
    if (window.confirm('Would you like to save these players stats?')) {
        try {
            const response = await axios.post('https://footballcustomformation.com/php_backend/update_playerForm.php', {
                players: players
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });
            console.log('Response from saving player stats:', response.data);
        } catch (error) {
            console.error('Problem saving players to mySQL', error);
        }
    }
}

export { fetchPlayerData, getPlayerStatsInQueue, savePlayerStats };