import React from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import PlayerCardMini from './PlayerCardComponents/PlayerCardMini';

const ReserveSlot = ({ player, index, handleDrop, setReserves, removeFromQueue, handleDoubleClickPlayer, onStatsChange, pushToQueue, addByPosition }) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.PLAYER,
    drop: (item) => handleDrop(item, index, 'reserves'),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div ref={drop} className={`reserve-slot ${isOver ? 'is-over' : ''}`}>
      {player ? (
        <PlayerCardMini
          player={player}
          index={index}
          removePlayer={removeFromQueue}
          setFunction={setReserves}
          isQueue={true}
          onPlayerDoubleClick={handleDoubleClickPlayer}
          onStatsChange={onStatsChange}
          pushToQueue={pushToQueue}
          addByPosition={addByPosition}
        />
      ) : (
        <div className="empty-slot"></div>
      )}
    </div>
  );
};

export default ReserveSlot;