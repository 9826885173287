import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import { MultiBackendConfig } from './MultiBackendConfig';
//import Cookies from 'js-cookie';
import CustomFormation from './CustomFormation'; // Import your CustomFormation component
import Login from './Login'; // Import your Login component
import Signup from './Signup';
import ResetPassword from './ResetPassword';
import ConfirmEmail from './ConfirmEmail';
import Payment from './Payment';
import LeagueSimulator from './GameSimulation/LeagueSimulator';
import Terms from './Terms';

const Main = () => {
    const [token, setToken] = useState(localStorage.getItem('token'));

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        setToken(storedToken);
    }, []);

    const handleLogin = (token) => {
        localStorage.setItem('token', token);
        setToken(token);
    };

    const handleLogout = (setUsername) => {
        localStorage.removeItem('token');
        console.log('Session storage', sessionStorage);
        sessionStorage.removeItem('savedPlayers');
        localStorage.removeItem('savedPlayers'); 
        setToken(null);
        setUsername('');
        window.location.reload();
    };

    return (
        <Router>
            <DndProvider DndProvider backend={MultiBackend} options={MultiBackendConfig}>
                <Routes>
                    <Route path="/login" element={<Login onLogin={handleLogin} />} />
                    <Route path="/FIFAFormationCreator" element={<CustomFormation token={token} handleLogout={handleLogout} />} />
                    <Route path="/" element={<CustomFormation token={token} handleLogout={handleLogout} />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/reset_password/:token" element={<ResetPassword />} />
                    <Route path="/confirm_email/:token" element={<ConfirmEmail />} />
                    <Route path="/payment/:token" element={<Payment />} />
                    <Route path="/terms/" element={<Terms />} />
                    <Route path="/league_simulator" element={<LeagueSimulator />}/>
                </Routes>
            </DndProvider>
        </Router>
    );
};

export default Main;