import React, { useState } from 'react';
import './stats-popup.css';

const TeamForm = ({ setTeamName, setVisibility, positions, canEdit }) => {
    const [displayName, setDisplayName] = useState('');
    const [captain, setCaptain] = useState('');

    const handleSave = () => {
        if (displayName.trim()) { // Ensure displayName is not just empty spaces
            setTeamName(displayName, captain, canEdit);
        } else {
            alert('Please enter a name for your team.');
        }
    };

    const calculateCaptainScore = (player) => {
        let age = 0;
        if (player.age.includes(';')) {
            const ageParts = player.age.split(';');
            age = parseInt(ageParts[1], 10);
        }

        return player.overall * (1 + age / 100);
    }

    return (
        < div className="birthday-input-popup" >
            <input
                type="text"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                placeholder="Create A Name for Your Team"
                onKeyDown={(e) => e.key === 'Enter' && setTeamName(e.target.value)} />

            <select
                value={captain}
                onChange={(e) => setCaptain(e.target.value)}>
                <option value="">Select captain</option>
                {Object.values(positions)
                    .filter(player => player)  // Filter out null or undefined players
                    .map(player => ({
                        ...player,
                        score: calculateCaptainScore(player)  // Calculate score for each player
                    }))
                    .sort((a, b) => b.score - a.score)  // Optionally sort players by score, descending
                    .map((player, index) => (
                        <option key={index} value={player.name}>
                            {player.name}: {player.overall}
                        </option>
                    ))}
            </select>
            <button onClick={handleSave}>Save Name</button>
            <button style={{ marginTop: '5px' }} onClick={() => setVisibility(false)}>Cancel</button>
        </div >
    )
}

export default TeamForm;