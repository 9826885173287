import React, { useState, useEffect, useRef } from 'react';
import PlayerCardMini from './PlayerCardComponents/PlayerCardMini.js';
import './QueueVisualizer.css';
import defaultImage from './resources/default_player.png';
import { getPlayerStatsInQueue, savePlayerStats } from './PlayerCardComponents/PlayerDataFetcher.js';

const QueueVisualizer = ({ queue, setQueue, removeFromQueue, clearQueue, findBestPosition, positionNames, bench, setBench, reserves, setReserves, 
    sortQueue, onStatsChange, saveAllChanges, pushToQueue, addByPosition }) => {
    const [swapMenuVisible, setSwapMenuVisible] = useState(false);
    const [playerToSwap, setPlayerToSwap] = useState(null);
    const [sortedBench, setSortedBench] = useState([]);
    const [sortedReserves, setSortedReserves] = useState([]);
    const [cardsPerRow, setCardsPerRow] = useState(6);
    const swapMenuRef = useRef(null);

    useEffect(() => {
        const updateCardsPerRow = () => {
            const screenWidth = window.innerWidth;
            const cards = Math.min(Math.floor(screenWidth / 250), 6);
            setCardsPerRow(cards);

            document.documentElement.style.setProperty('--player-card-width', `250px`);
        }

        updateCardsPerRow();
        window.addEventListener('resize', updateCardsPerRow);
    }, []);

    const handleDoubleClickPlayer = (player) => {
        setPlayerToSwap(player);
        sortQueue(player.position.split(';')[0], bench, setSortedBench);
        sortQueue(player.position.split(';')[0], reserves, setSortedReserves);
        //console.log(`${player.name} goals: ${player.goals}, assists: ${player.assists}, form: ${player.form}`);
        setSwapMenuVisible(true);
    };

    const swapBetweenQueues = (player1, queue1, queue2, setQueue1, setQueue2) => {
        const newQueue = [...queue1];
        const newBench = [...queue2];
        const queue1Type = queue1 === bench ? 'bench' : queue1 === reserves ? 'reserves' : 'queue';
        const queue2Type = queue2 === bench ? 'bench' : queue2 === reserves ? 'reserves' : 'queue';
        //sconsole.log(`Queue 1 type ${queue1Type}\nQueue 2 type ${queue2Type}`);

        //Finds index of players that will be swapped
        const queueIndex = newQueue.findIndex(p => p.id === playerToSwap.id);
        const queue2Index = newBench.findIndex(p => p.id === player1.id);

        //Swap
        if (queueIndex !== -1 && queue2Index !== -1) {
            player1.type = queue1Type;
            playerToSwap.type = queue2Type;
            newQueue[queueIndex] = player1;
            newBench[queue2Index] = playerToSwap;
            setQueue1(newQueue);
            setQueue2(newBench);

            setSwapMenuVisible(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (swapMenuRef.current && !swapMenuRef.current.contains(event.target)) {
                setSwapMenuVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [setSwapMenuVisible, swapMenuRef]);

    const chunks = queue.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / cardsPerRow);

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);

    return (
        <div className="queue-visualizer">
            {swapMenuVisible && (
                <div className="swap-menu" ref={swapMenuRef}>
                    <h4>Select a player from bench to swap</h4>
                    <h5>Bench</h5>
                    <div className="swap-menu-list">
                        {sortedBench.map(player => (
                            <div key={player.id} onClick={() => swapBetweenQueues(player, queue, bench, setQueue, setBench)} className="swap-menu-player">
                                <img src={player.photo} onError={(e) => e.target.src = defaultImage} alt={player.name} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                                <span>{player.name}</span>
                                <span style={{ marginLeft: '20px' }}>{player.position.split(';')[0]}</span>
                            </div>
                        ))}
                        <h5>Reserves</h5>
                        {sortedReserves.map(player => (
                            <div key={player.id} onClick={() => swapBetweenQueues(player, queue, reserves, setQueue, setReserves)} className="swap-menu-player">
                                <img src={player.photo} onError={(e) => e.target.src = defaultImage} alt={player.name} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                                <span>{player.name}</span>
                                <span style={{ marginLeft: '20px' }}>{player.position.split(';')[0]}</span>
                            </div>
                        ))}
                    </div>
                    <button className="swap-menu-cancel" onClick={() => setSwapMenuVisible(false)}>Cancel</button>
                </div>
            )}
            <h2 className="title">Player Queue (Add All 11 Players First)</h2>
            <h4 className="subtitle">Double Click on Players to Switch With Bench</h4>
            <div className="queue-container">
                {chunks.map((chunk, index) => (
                    <div key={index} className="queue-row"><div></div>
                        {chunk.map((player, playerIndex) => (
                            <PlayerCardMini
                                key={playerIndex}
                                player={player}
                                removePlayer={removeFromQueue}
                                setFunction={setQueue}
                                index={playerIndex + cardsPerRow * index}
                                isQueue={true}
                                onPlayerDoubleClick={handleDoubleClickPlayer}
                                onStatsChange={onStatsChange}
                                pushToQueue={pushToQueue}
                                addByPosition={addByPosition}
                            />
                        ))}
                    </div>
                ))}
            </div>
            {queue.length >= 11 && <button className="action-button" onClick={() => findBestPosition(positionNames, queue)}>Find Best Positions</button>}
            {queue.length > 0 && <button className="action-button" onClick={() => clearQueue(setQueue)}>Clear Queue</button>}
            {queue.length > 0 && <button className="action-button" onClick={() => getPlayerStatsInQueue(queue, saveAllChanges)}>Get Player Stats</button>}
            {queue.length > 0 && <button className="action-button" onClick={() => savePlayerStats(queue)}>Save Player Stats</button>}
        </div>
    );
}

export default QueueVisualizer;