import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { TouchTransition } from 'react-dnd-multi-backend';

export const MultiBackendConfig = {
    backends: [
        {
            backend: HTML5Backend,
            transition: null, // This is the default, no transition
        },
        {
            backend: TouchBackend,
            options: { enableMouseEvents: true },
            preview: true, // Enable touch backend preview
            transition: TouchTransition,
        },
    ],
};