import React from 'react';
import './Terms.css';

const Terms = () => {
    return (
        <div className="terms-container">
            <h1>Terms of Service</h1>
            <section>
                <h2>Introduction</h2>
                <p>Welcome to Football Custom Formation. By using our service, you agree to the following terms...</p>
            </section>
            <section>
                <h2>Eligibility</h2>
                <p>To use our services, you must be at least 13 years old and comply with all applicable laws...</p>
            </section>
            <section>
                <h2>Account Registration</h2>
                <p>Users are required to register an account to access certain features...</p>
            </section>
            <section>
                <h2>Service Description</h2>
                <p>Our service allows you to customize teams, simulate matches, and receive match commentary...</p>
            </section>
            <section>
                <h2>User Conduct</h2>
                <p>Users must adhere to the following rules and guidelines when using our platform...</p>
            </section>
            <section>
                <h2>Intellectual Property</h2>
                <p>All content provided through our service is protected by intellectual property laws...</p>
            </section>
            <section>
                <h2>Privacy Policy</h2>
                <p>We are committed to protecting your privacy and will only use your data as described...</p>
            </section>
            <section>
                <h2>Disclaimers and Limitation of Liability</h2>
                <p>Our service is provided "as is" without any warranties or guarantees...</p>
            </section>
            <section>
                <h2>Termination</h2>
                <p>We reserve the right to suspend or terminate accounts that violate our terms...</p>
            </section>
            <section>
                <h2>Governing Law</h2>
                <p>These terms are governed by the laws of [Your Jurisdiction]...</p>
            </section>
            <section>
                <h2>Changes to the Terms</h2>
                <p>We may modify these terms at any time, and your continued use of the service indicates acceptance...</p>
            </section>
            <section>
                <h2>Contact Information</h2>
                <p>If you have any questions or concerns about these terms, please contact us at footba17@footballcustomformation.com...</p>
            </section>
        </div>
    );
};

export default Terms;