//Stats for strikers or center forwards
const strikerStats = {
    finishing: 0.2,
    positioning: 0.12,
    headingAccuracy: 0.1,
    reactions: 0.1,
    shotPower: 0.1,
    ballControl: 0.08,
    skill_dribbling: 0.08,
    volleys: 0.05,
    longShots: 0.05,
    acceleration: 0.05,
    sprintSpeed: 0.04,
    strength: 0.03
};

const forwardStats = {
    finishing: 0.12,
    positioning: 0.12,
    ballControl: 0.11,
    skill_dribbling: 0.11,
    reactions: 0.1,
    longShots: 0.1,
    shotPower: 0.1,
    shortPassing: 0.06,
    headingAccuracy: 0.05,
    vision: 0.05,
    acceleration: 0.04,
    sprintSpeed: 0.04,
};

//Stats for wingers
const wingerStats = {
    crossing: 0.16,
    skill_dribbling: 0.16,
    ballControl: 0.13,
    shortPassing: 0.1,
    positioning: 0.09,
    reactions: 0.06,
    acceleration: 0.06,
    sprintSpeed: 0.06,
    agility: 0.05,
    vision: 0.05,
    finishing: 0.04,
    longShots: 0.04
};

//Stats for LM and RM
const wideStats = {
    crossing: 0.14,
    skill_dribbling: 0.14,
    shortPassing: 0.12,
    ballControl: 0.12,
    longPassing: 0.08,
    vision: 0.08,
    positioning: 0.07,
    reactions: 0.07,
    acceleration: 0.05,
    sprintSpeed: 0.05,
    stamina: 0.05,
    agility: 0.03
};

//Stats for center attacking midfielders
const camStats = {
    shortPassing: 0.16,
    vision: 0.16,
    ballControl: 0.13,
    positioning: 0.12,
    skill_dribbling: 0.11,
    reactions: 0.08,
    longShots: 0.06,
    finishing: 0.05,
    shotPower: 0.05,
    acceleration: 0.04,
    agility: 0.04
};

//Stats for center midfielders
const cmStats = {
    shortPassing: 0.15,
    longPassing: 0.13,
    vision: 0.12,
    ballControl: 0.1,
    skill_dribbling: 0.09,
    positioning: 0.08,
    interceptions: 0.08,
    reactions: 0.08,
    standingTackle: 0.06,
    stamina: 0.06,
    longShots: 0.05
};

//Stats for center defensive midfielders
const cdmStats = {
    shortPassing: 0.13,
    interceptions: 0.13,
    longPassing: 0.11,
    marking: 0.1,
    standingTackle: 0.1,
    reactions: 0.09,
    ballControl: 0.09,
    vision: 0.08,
    stamina: 0.06,
    strength: 0.06,
    aggression: 0.05
};

//Stats for full backs (RB and LB)
const fullBackStats = {
    slidingTackle: 0.13,
    standingTackle: 0.12,
    interceptions: 0.12,
    marking: 0.1,
    stamina: 0.08,
    reactions: 0.08,
    crossing: 0.07,
    headingAccuracy: 0.07,
    ballControl: 0.07,
    shortPassing: 0.06,
    aggression: 0.05,
    sprintSpeed: 0.05
}

//Stats for center backs
const cbStats = {
    marking: 0.15,
    slidingTackle: 0.15,
    standingTackle: 0.15,
    headingAccuracy: 0.1,
    strength: 0.1,
    aggression: 0.08,
    interceptions: 0.08,
    shortPassing: 0.05,
    reactions: 0.05,
    ballControl: 0.05,
    jumping: 0.04
};

/** All stats arrays */
const allStats = {
    striker: {
        stats: strikerStats,
        positions: ['ST', 'RS', 'LS']
    },
    forward: {
        stats: forwardStats,
        positions: ['CF', 'LF', 'RF']
    },
    cam: {
        stats: camStats,
        positions: ['CAM', 'LAM', 'RAM']
    },
    winger: {
        stats: wingerStats,
        positions: ['LW', 'RW']
    },
    wide: {
        stats: wideStats,
        positions: ['LM', 'RM']
    },
    cm: {
        stats: cmStats,
        positions: ['LCM', 'RCM', 'CM']
    },
    cdm: {
        stats: cdmStats,
        positions: ['LDM', 'RDM', 'CDM']
    },
    fullBack: {
        stats: fullBackStats,
        positions: ['LB', 'RB', 'LWB', 'RWB']
    },
    centerBack: {
        stats: cbStats,
        positions: ['LCB', 'RCB', 'CB']
    }
};

//Calculates player's overall if any stats are changed
const calculateOverall = (player) => {
    let maxOverall = 0; // To keep track of the highest overall rating
    let overallRatings = []; // To store overall ratings for each stat group

    if (!player || typeof player !== 'object') {
        console.error('Invalid player object');
        return;
    }

    // Ensure allStats is defined and is an object
    if (!allStats || typeof allStats !== 'object') {
        console.error('allStats is undefined or not an object');
        return;
    }

    let rating = 0;
    let increment = 0;
    //Calculate for Goalkeeper
    rating = player.reactions * 0.06 + player.gkDiving * 0.24 + player.gkHandling * 0.22 + player.gkPos * 0.22 + player.gkReflex * 0.22 + player.gkKicking * 0.04;
    //console.log('Current rating:', rating);
    rating = Math.round(rating);
    increment = getIncrement('GK', rating, player);

    player.GK = `${rating}+${increment}`;
    if (player.position === 'GK') {
        player.overall = rating + increment;
    }

    //All other positions
    Object.entries(allStats).forEach(([key, { stats, positions }]) => {
        rating = 0;
        // Ensure 'stats' is not null or undefined before trying to use Object.entries on it
        if (stats) {
            Object.entries(stats).forEach(([stat, weight]) => {
                if (player.hasOwnProperty(stat)) {
                    const statValue = Number(player[stat]) || 0;
                    //console.log('Current stat value for', stat, ':', statValue, 'x', weight, '=', statValue*weight);
                    rating += statValue * weight;
                }
            });

            rating = Math.round(rating);
            // Ensure getIncrement is correctly implemented to handle positions[0], rating, and player.potential
            const increment = getIncrement(positions[0], rating, player.potential);
            overallRatings.push(rating + increment);

            positions.forEach(pos => {
                player[pos] = `${rating}+${increment}`;
                //console.log('Current rating + increment for', key, ':', player[pos]);
            });

            //console.log('Rating for', key, ':', rating + increment); // Use 'key' to log which part of allStats you're processing
        } else {
            console.error(`Stats for ${key} is undefined or null.`);
        }
    });
    // Find the maximum overall rating from the calculated overalls
    maxOverall = Math.max(...overallRatings);

    return maxOverall;
}

// Function to get increment based on position type and overall rating
const getIncrement = (positionType, overallRating, player) => {
    var factor = (positionType === 'LW' || positionType === 'RW' || positionType.includes('F')) ? 0 : (player.intRep > 2 ? 3 : 1);

    return factor > (player.potential - overallRating) ? player.potential - overallRating : factor;
}

export { calculateOverall };