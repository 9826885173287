import React, {useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './emailConfirmed.css';

const EmailConfirmed = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [confirmationMessage, setConfirmationMessage] = useState('');

    useEffect(() => {
        const confirmEmail = async () => {
            try {
                const response = await axios.get(`http://footballcustomformation.com/php_backend/confirm_email.php?token=${token}`);
                setConfirmationMessage('Thank you for confirming your email.');
            } catch (error) {
                setConfirmationMessage('Failed to confirm email. The token may be invalid or expired.', error.response);
            }
        }
        confirmEmail();
    }, [token]);

    return (
        <div className="email-confirmed-container">
            <h2>{confirmationMessage}.</h2>
            <button onClick={() => navigate('/')}>Redirect</button>
        </div>
    );
}

export default EmailConfirmed;