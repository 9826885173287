import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './reset-password.css';

const ResetPassword = () => {
    const {token} = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://footballcustomformation.com/php_backend/reset_password.php', {
                token,
                password,
                confirmPassword
            });
            setSuccessMessage('Password reset successfully');
            setErrors({});

            setTimeout(() => {
                navigate('/login');
            }, 2000);
        } catch (error) {
            setErrors({ form: 'Failed to reset password' });
            console.error('Password could not be reset:', error.response);
        }
    };
    
    return (
        <div className="reset-password-container">
            <h2>Reset Password</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>New Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {errors.password && <span className="error">{errors.password}</span>}
                </div>
                <div className="form-group">
                    <label>Confirm New Password</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
                </div>
                <button type="submit">Reset Password</button>
                {errors.form && <span className="error">{errors.form}</span>}
                {successMessage && <span className="success">{successMessage}</span>}
            </form>
        </div>
    );
};

export default ResetPassword;