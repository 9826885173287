import React, {useState, useEffect} from 'react';
import SoccerBall from '../resources/soccerball.png';

const Ball = (props) => {
    const [position, setPosition] = useState({x: props.fieldWidth * 1/2, y: props.fieldHeight * 1/2 + 10});
    const [height, setHeight] = useState(0);
    const [speed, setSpeed] = useState({x: 0, y: 0});
    const [isOutOfBounds, setIsOutOfBounds] = useState(false);
    
    //Sets speed and direction of updating the position
    useEffect(() => {
        const timer = setInterval(() => {
            setPosition(prevPos => ({
                x: prevPos.x + speed.x,
                y: prevPos.y + speed.y
            }))
            checkBounds();
        }, 100);
    }, [speed]);

    const checkBounds = () => {
        if (position.x < 0 || position.x > props.fieldWidth || position.y < 0 || position.y > props.fieldHeight) {
            setIsOutOfBounds(true);
            setSpeed({ x: 0, y: 0 });
        } else {
            setIsOutOfBounds(false);
        }
    }

    return (
        <img 
            src={SoccerBall}
            alt="Soccer Ball"
            style={{
                position: 'absolute',
                left: `${position.x}px`,
                top: `${position.y}px`,
                width: '20px',
                height: '20px',
                transform: `translate(-50%, -50%) scale(${1 + height / 100})`, // Adjust scale based on the height
                transition: 'left 100ms, top 100ms' // Smooth movement transition
            }}
        />
    );
}

export default Ball;