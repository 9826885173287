import React from 'react';
import PlayerPhoto from '../PlayerCardComponents/PlayerPhoto';
import './player-stats-menu.css';

const PlayerStatsMenu = ({ player, events, shots, isSubstitute, logo, onClose }) => {
    const playerEvents = events.filter(event => event.name === player.name || event.substitute === player.name || (event.assister && event.assister === player.name));
    let goalCount = 0;
    let assistCount = 0;
    let yellowCardCount = 0;
    let redCardCount = 0;
    let yellowToRedCount = 0;
    let minutesPlayed = isSubstitute ? 0 : 90;

    playerEvents.forEach(event => {
        switch (event.type) {
            case 'goal':
                if (event.name === player.name) {
                    goalCount++;
                } else if (event.assister === player.name) {
                    assistCount++;
                }
                break;
            case 'yellowCard':
                yellowCardCount++;
                break;
            case 'redCard':
                redCardCount++;
                break;
            case 'yellowToRed':
                yellowToRedCount++;
                break;
            case 'substitution':
                if (event.name === player.name && !isSubstitute) {
                    const minute = event.minute.toString()
                    // Player was substituted out
                    minutesPlayed = minute.includes('+') ? 89 : minute;
                } else if (event.substitute === player.name && isSubstitute) {
                    //console.log(`${player.name} was substitute`);
                    // Player was substituted in
                    const minute = event.minute.toString();
                    minutesPlayed = minute.includes('+') ? 1 : (90 - minute);
                }
                break;
            default:
                break;
        }
    });

    /**
     * Function that takes a rating to determine the color of the background
     * of the rating
     * @param {Double} rating 
     * @returns color based on the rating
     */
    const getRatingColor = (rating) => {
        if (rating >= 8.0) {
            return '#006400'; // Dark green
        } else if (rating >= 7 && rating < 8) {
            return '#32CD32'; // Lime green
        } else if (rating >= 6 && rating < 7) {
            return '#FFD700'; // Yellow
        } else if (rating >= 5 && rating < 6) {
            return '#FFA500'; // Orange
        } else {
            return '#FF4500'; // Red
        }
    };

    return (
        <div className="player-match-events">
            <div className="player-stats-header">
                <div className="player-info">
                    <PlayerPhoto player={player} className="player-photo" />
                    <div className="player-details">
                        <h2>{player.name}</h2>
                        <div className="player-club">
                            <img src={logo.logo} alt={logo.club} className="club-logo" />
                            <span>{logo.club} {player.kitNumber !== 0 ? `#${player.clubNumber}` : `${player.currentPosition.name}` }</span>
                        </div>
                    </div>
                </div>
                <button className="close-button" onClick={onClose}>X</button>
            </div>
            {/* Match rating */}
            <div className="match-rating">
                <p>Match rating</p>
                <div
                    className="rating-box"
                    style={{ backgroundColor: getRatingColor(player.rating) }}  // Apply background color here
                >
                    <span>{player.rating.toFixed(1)}</span>
                </div>
            </div>

            {/* Player stats content */}
            <div className="player-stats-content">
                <div className="stat-row">
                    <span>Minutes played</span>
                    <span>{minutesPlayed}</span>
                </div>
                <div className="stat-row">
                    <span>Goals</span>
                    <span>{goalCount}</span>
                </div>
                <div className="stat-row">
                    <span>Assists</span>
                    <span>{assistCount}</span>
                </div>
                <div className="stat-row">
                    <span>Total {(shots.type && shots.type === 'save') ? 'saves' : 'shots'}</span>
                    <span>{shots.shots || 0}</span>
                </div>
                <div className="stat-row">
                    <span>Yellow cards</span>
                    <span>{yellowCardCount + yellowToRedCount}</span>
                </div>
                <div className="stat-row">
                    <span>Red cards</span>
                    <span>{redCardCount}</span>
                </div>
            </div>
        </div>
    );
};

export default PlayerStatsMenu;