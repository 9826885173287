import React, { useState, useEffect } from 'react';
import './league-statistics.css';

const LeagueStatistics = ({ fixtureResults, getTeamStatistics, leagueTable }) => {
    const [statsVisible, setStatsVisible] = useState(false);
    const [statistics, setStatistics] = useState(null);
    const [tableInitialized, setTableInitialized] = useState(false);

    useEffect(() => {
        if (tableInitialized) {
            const allTeamsStats = calculateStatistics(fixtureResults);
            setStatistics(allTeamsStats);
        }
    }, [leagueTable]);

    const getTeamResults = (team, fixtureResults) => {
        let teamMatches = [];
        fixtureResults.forEach(matchday => {
            matchday.forEach(match => {
                if (match.home === team || match.away === team) {
                    teamMatches.push(match);
                }
            });
        });
        return teamMatches;
    };

    const toggleStatsVisibility = () => {
        if (!statistics) {
            // Run calculations only the first time
            const allTeamsStats = calculateStatistics(fixtureResults);
            setStatistics(allTeamsStats);
            setTableInitialized(true);
        }
        calculateStatistics(fixtureResults);
        setStatsVisible(!statsVisible);
    }

    const calculateStatistics = (fixtureResults) => {
        if (!fixtureResults) {
            console.error('Fixture results not defined');
        }
        //Extract all unique teams
        const teams = fixtureResults[0].reduce((acc, fixture) => {
            if (!acc.includes(fixture.home)) acc.push(fixture.home);
            if (!acc.includes(fixture.away)) acc.push(fixture.away);
            return acc;
        }, []).sort((a, b) => a.localeCompare(b));

        const teamStatistics = teams.map(team => {
            const teamResults = getTeamResults(team, fixtureResults);
            return getTeamStatistics(leagueTable, team, teamResults);
        });
        //console.log ('Team statistics:', teamStatistics);

        const longestWinningStreak = findLongestStreak(teamStatistics, 'longestWinningStreak');
        const longestUnbeatenStreak = findLongestStreak(teamStatistics, 'longestUnbeatenStreak');
        const longestWinlessStreak = findLongestStreak(teamStatistics, 'longestWinlessStreak');
        const longestLosingStreak = findLongestStreak(teamStatistics, 'longestLosingStreak');
        const largestHomeVictory = findLargestVictory(teamStatistics, 'largestHomeVictory');
        const largestAwayVictory = findLargestVictory(teamStatistics, 'largestAwayVictory');
        const highestScoringMatch = findHighestScoringMatch(fixtureResults);

        const longestStreaks = {
            longestWinningStreak,
            longestUnbeatenStreak,
            longestWinlessStreak,
            longestLosingStreak,
            largestHomeVictory,
            largestAwayVictory,
            highestScoringMatch
        };

        //console.log('Longest streaks:', longestStreaks);
        return longestStreaks;
    }

    // Helper function to find the longest streak
    const findLongestStreak = (teamStats, streakType) => {
        let longestStreak = [];
        teamStats.forEach(team => {
            const stats = { team: team.team, ...team[streakType] };
            if (stats.length > 0) {
                if (longestStreak.length === 0 || stats.length === longestStreak[0].length) {
                    longestStreak.push(stats);
                } else if (stats.length > longestStreak[0].length) {
                    longestStreak = [stats];
                }
            }
        });
        return longestStreak;
    };

    // Helper function to find the largest victories
    const findLargestVictory = (teamStats, victoryType) => {
        let largestVictory = [];
        teamStats.forEach(team => {
            const stats = { ...team[victoryType] };
            Object.values(stats).forEach(stat => {
                const result = { ...stat, team: team.team };
                if (largestVictory.length === 0 || result.goalDifference === largestVictory[0].goalDifference) {
                    largestVictory.push(result);
                } else if (result.goalDifference > largestVictory[0].goalDifference) {
                    largestVictory = [result];
                }
            });
        });

        return largestVictory;
    };

    // Helper function to find the highest-scoring match
    const findHighestScoringMatch = (fixtureResults) => {
        let highestScoring = [];
        fixtureResults.forEach(matchday => {
            matchday.forEach(match => {
                const totalGoals = match.homeGoals + match.awayGoals;
                if (highestScoring.length === 0 || totalGoals === highestScoring[0].totalGoals) {
                    highestScoring.push({ match, totalGoals });
                } else if (totalGoals > highestScoring[0].totalGoals) {
                    highestScoring = [{ match, totalGoals }];
                }
            });
        });
        return highestScoring;
    };

    return (
        <div className="league-statistics">
            <button onClick={toggleStatsVisibility}>
                {statsVisible ? 'Hide Statistics' : 'Show Statistics'}
            </button>
            {statsVisible && statistics && (
                <div>
                    <h3>Longest Winning Streak</h3>
                    {statistics.longestWinningStreak.map((team, index) => (
                        <p key={index}>{team.team}: {team.length} matches</p>
                    ))}

                    <h3>Longest Unbeaten Streak</h3>
                    {statistics.longestUnbeatenStreak.map((team, index) => (
                        <p key={index}>{team.team}: {team.length} matches</p>
                    ))}

                    <h3>Longest Winless Streak</h3>
                    {statistics.longestWinlessStreak.map((team, index) => (
                        <p key={index}>{team.team}: {team.length} matches</p>
                    ))}

                    <h3>Longest Losing Streak</h3>
                    {statistics.longestLosingStreak.map((team, index) => (
                        <p key={index}>{team.team}: {team.length} matches</p>
                    ))}

                    <h3>Largest Home Victory</h3>
                    {statistics.largestHomeVictory.map((team, index) => (
                        <p key={index}>{team.team} vs {team.opponent}: {team.scoreline}</p>
                    ))}

                    <h3>Largest Away Victory</h3>
                    {statistics.largestAwayVictory.map((team, index) => (
                        <p key={index}>{team.team} vs {team.opponent}: {team.scoreline}</p>
                    ))}

                    <h3>Highest Scoring Match</h3>
                    {statistics.highestScoringMatch.map((matchData, index) => (
                        <p key={index}>{matchData.match.home} vs {matchData.match.away}: {matchData.match.homeGoals} - {matchData.match.awayGoals} (Total: {matchData.totalGoals} goals)</p>
                    ))}
                </div>
            )}
        </div>
    );
}

export default LeagueStatistics;