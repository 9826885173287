import React, { useState, useEffect, useRef } from "react";
import defaultImage from './resources/default_player.png';
import './BenchVisualizer.css';
import BenchSlot from './BenchSlot';

const BenchVisualizer = ({ bench, setBench, reserves, setReserves, removeFromQueue, clearBench, sortQueue, handleDrop, onStatsChange, pushToQueue, addByPosition}) => {
    const [benchVisible, setBenchVisible] = useState(false);
    const [playerToSwap, setPlayerToSwap] = useState(null);
    const [sortedReserves, setSortedReserves] = useState(null);
    const [swapMenuVisible, setSwapMenuVisible] = useState(false);
    const [cardsPerRow, setCardsPerRow] = useState(5);
    const swapMenuRef = useRef(null);

    const toggleVisibility = () => setBenchVisible(!benchVisible);

    useEffect(() => {
        const updateCardsPerRow = () => {
            const screenWidth = window.innerWidth;
            const cards = Math.min(Math.floor(screenWidth / 250), 5);
            setCardsPerRow(cards);
        }

        updateCardsPerRow();
        window.addEventListener('resize', updateCardsPerRow);
    }, []);

    const handleDoubleClickPlayer = (player) => {
        setPlayerToSwap(player);
        sortQueue(player.position.split(';')[0], reserves, setSortedReserves);
        setSwapMenuVisible(true);
    };

    const swapBenchAndReserves = (player) => {
        const newBench = [...bench];
        const newReserves = [...reserves];

        //Finds index of players that will be swapped
        const benchIndex = newBench.findIndex(p => p.id === playerToSwap.id);
        const reservesIndex = newReserves.findIndex(p => p.id === player.id);

        //Swap
        if (benchIndex !== -1 && reservesIndex !== -1) {
            player.type = 'bench';
            playerToSwap.type = 'reserves';
            newBench[benchIndex] = player;
            newReserves[reservesIndex] = playerToSwap;
            setBench(newBench);
            setReserves(newReserves);

            setSwapMenuVisible(false);
        }
    };

    const chunks = bench.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / cardsPerRow);

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);
        return resultArray;
    }, [])

    //Fill rest of bench with empty players
    for (let i = bench.length; i < 10; i++) {
        if (!chunks[Math.floor(i / cardsPerRow)]) {
            chunks[Math.floor(i / cardsPerRow)] = [];
        }
        chunks[Math.floor(i / cardsPerRow)].push(null);
    }

    return (
        <div className="bench-visualizer">
            {swapMenuVisible && (
                <div className="swap-menu" ref={swapMenuRef}>
                    <h4>Select a player from reserves to swap</h4>
                    <h5>Reserves</h5>
                    <div className="swap-menu-list">
                        {sortedReserves.map(player => (
                            <div key={player.id} onClick={() => swapBenchAndReserves(player)} className="swap-menu-player">
                                <img src={player.photo} onError={(e) => e.target.src = defaultImage} alt={player.name} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                                <span>{player.name}</span>
                                <span style={{ marginLeft: '20px' }}>{player.position.split(';')[0]}</span>
                            </div>
                        ))}
                    </div>
                    <button onClick={() => setSwapMenuVisible(false)}>Cancel</button>
                </div>
            )}
            <h2>Bench (Up to 10 Players) <button className="toggle-bench-btn" onClick={toggleVisibility}>{benchVisible ? 'Hide' : 'Show'}</button></h2>
            <div className={`bench-container ${benchVisible ? 'bench-visible' : ''}`}>
                {chunks.map((chunk, chunkIndex) => (
                    <div key={chunkIndex} className="bench-row">
                        {chunk.map((player, playerIndex) => (
                            <BenchSlot
                                key={playerIndex}
                                player={player}
                                index={playerIndex + cardsPerRow * chunkIndex}
                                handleDrop={handleDrop}
                                setBench={setBench}
                                removeFromQueue={removeFromQueue}
                                handleDoubleClickPlayer={handleDoubleClickPlayer}
                                onStatsChange={onStatsChange}
                                pushToQueue={pushToQueue}
                                addByPosition={addByPosition}
                            />
                        ))}
                    </div>
                ))}
            </div>
            {bench.length > 0 && <button className="button-clear-bench" onClick={() => clearBench(setBench)}>Clear Bench</button>}
        </div>
    );
};

export default BenchVisualizer;