import React, { useState, useEffect, useRef } from 'react';
import { fetchFlags, fetchLogos } from './SearchBar';
import axios from 'axios';
import unaccent from 'unaccent';
import './team-viewer.css';

const TeamViewer = ({createTeam, setHomeStadium, setCurrentTeam, setPlayersFromTeam }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const [flags, setFlags] = useState([]);
    const [logos, setLogos] = useState([]);

    const [flagsLoaded, setFlagsLoaded] = useState(false);
    const [logosLoaded, setLogosLoaded] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const dropdownRef = useRef(null);

    /**Sofifa function that is reliant on the website not blocking me
    const getPlayersFromTeam = async (team, id) => {
        try {
            const url = `https://footballcustomformation.com/php_backend/web_server.php/FIFAFormationCreator/team/${id}`;
            const response = await axios.get(url);
            const html = response.data;
            const $ = cheerio.load(html);
            const players = {
                teamId: id,
                teamName: team.name,
                starting: [],
                sub: [],
                res: []
            };

            $('table:first').find('tbody tr').each((index, element) => {
                const playerRow = $(element);
                const role = playerRow.attr('class');
                const playerName = playerRow.find('td a').first().text().trim();
                const playerLink = playerRow.find('td a').first().attr('href');
                const playerIdMatch = playerLink.match(/\/player\/(\d+)\//);
                const playerId = playerIdMatch ? playerIdMatch[1] : null;
                if (playerId) {
                    const playerInfo = { name: playerName, id: playerId };
                    // Add player to the corresponding category based on their role
                    if (role.includes('starting')) {
                        players.starting.push(playerInfo);
                    } else if (role.includes('sub')) {
                        players.sub.push(playerInfo);
                    } else if (role.includes('res')) {
                        players.res.push(playerInfo);
                    }
                }
            });
            //console.log(players);

            setTeamPlayers(players);
            let newQueue = [];

            for (const player of players.starting) {
                let currentPlayer = playerData.find(p => p.id === player.id);
                if (!currentPlayer) {
                    // Await the result of addNewPlayer
                    currentPlayer = await addNewPlayer(player.id);
                    if (currentPlayer) {
                        setPlayerData(prevPlayerData => [...prevPlayerData, currentPlayer]);
                    }
                }
                if (currentPlayer) {
                    if ((currentPlayer.club !== team.name || currentPlayer.clubLogo !== team.image) && team.type === 'club') {
                        currentPlayer.clubLogo = team.image;
                        currentPlayer.club = team.name;
                        // Get club logo for new team
                    } 
                    if (!currentPlayer.type) currentPlayer.type = 'queue';
                    newQueue.push(currentPlayer);
                    //console.log(currentPlayer.name, 'added to queue', currentPlayer.clubLogo);
                }
            }
            setQueue(newQueue);
            newQueue = [];

            //Get players for bench
            for (const player of players.sub) {
                let currentPlayer = playerData.find(p => p.id === player.id);
                if (!currentPlayer) {
                    // Await the result of addNewPlayer
                    currentPlayer = await addNewPlayer(player.id);
                    if (currentPlayer) {
                        setPlayerData(prevPlayerData => [...prevPlayerData, currentPlayer]);
                    }
                }
                if (currentPlayer) {
                    if ((currentPlayer.club !== team.name || currentPlayer.clubLogo !== team.image) && team.type === 'club') {
                        currentPlayer.clubLogo = team.image;
                        currentPlayer.club = team.name;
                        // Get club logo for new team
                    }
                    if (!currentPlayer.type) currentPlayer.type = 'bench';
                    newQueue.push(currentPlayer);
                    //console.log(currentPlayer.name, 'added to queue', currentPlayer.clubLogo);
                }
            }
            setBench(newQueue);
            newQueue = [];

            //Get players for reserves
            for (const player of players.res) {
                let currentPlayer = playerData.find(p => p.id === player.id);
                if (!currentPlayer) {
                    // Await the result of addNewPlayer
                    currentPlayer = await addNewPlayer(player.id);
                    if (currentPlayer) {
                        setPlayerData(prevPlayerData => [...prevPlayerData, currentPlayer]);
                    }
                }
                if (currentPlayer) {
                    if ((currentPlayer.club !== team.name || currentPlayer.clubLogo !== team.image) && team.type === 'club') {
                        currentPlayer.clubLogo = team.image;
                        currentPlayer.club = team.name;
                        // Get club logo for new team
                    }
                    if (!currentPlayer.type) currentPlayer.type = 'reserves';
                    newQueue.push(currentPlayer);
                    //console.log(currentPlayer.name, 'added to queue', currentPlayer.clubLogo);
                }
            }
            setReserves(newQueue)
            setEditingTeam(null);
        } catch (error) {
            console.error('Cannot find link for team', error);
        }
    };
    */

    //ITEM IS A TEAM OBJECT
    const handleSelectTeam = (team) => {
        setSearchTerm('');
        setShowResults(false);
        console.log('Team name', team.name);

        axios.post('https://footballcustomformation.com/php_backend/fetch_teamId.php', {
            teamName: team.name,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        })
            .then((response) => {
                //console.log('Successful response for fetching teamID:', response.data);
                if (!response.data.success) {
                    alert('Team does not exist in database. Team may not be current');
                    setCurrentTeam(response.data);
                    createTeam(team);
                } else {
                    setHomeStadium(response.data['homeStadium']);
                    const teamData = response.data.leagueTeamData;
                    console.log('Team data:', teamData);
                    setCurrentTeam(teamData);
                    setPlayersFromTeam(teamData);
                    //getPlayersFromTeam(team, id);
                }
            })
            .catch((error) => {
                console.error('There was an error!', error);
            });
    }

    useEffect(() => {
        fetchLogos(setLogos, setLogosLoaded);
        fetchFlags(setFlags, setFlagsLoaded);
    }, []);

    const enhancedUnaccent = (text) => {
        // First, use unaccent to remove most accents
        let normalizedText = unaccent(text);

        // Manually replace any specific characters missed by unaccent
        normalizedText = normalizedText.replace(/ú/g, 'u');
        return normalizedText;
    };

    //Handles changing searchTerm by user
    useEffect(() => {
        if (setFlagsLoaded && setLogosLoaded) {
            const term = searchTerm.toLowerCase();

            if (term === '') {
                setSearchResults([]);
                return;
            }

            // Combine logos and flags for searching
            const combinedSearchItems = [
                ...logos.map(club => ({ type: 'club', name: club.club, image: club.logo })),
                ...flags.map(flag => ({ type: 'country', name: flag.nationality, image: flag.flag })),
            ];

            const matchingItems = combinedSearchItems.filter(item => {
                const refinedTerm = enhancedUnaccent(item.name);
                return refinedTerm.toLowerCase().includes(term)
            });

            setSearchResults(matchingItems);
            setShowResults(true);
        } else {
            setShowResults(false);
        }
    }, [searchTerm, flagsLoaded, logosLoaded]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowResults(false);
        }
    };

    useEffect(() => {
        if (showResults) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [showResults]);

    return (
        <div className="search-dropdown" ref={dropdownRef}>
            <input
                type="text"
                placeholder="Search for a club or national team..."
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={() => setShowResults(true)}
                autoFocus
            />
            {showResults && searchResults.length > 0 && (
                <ul className="search-dropdown-results">
                    {searchResults.map((item, index) => (
                        <li key={index} onClick={() => handleSelectTeam(item)}>
                            <img src={item.image} alt={item.name} /> {item.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default TeamViewer;