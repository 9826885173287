import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './signup.css';
import { IoArrowBack } from 'react-icons/io5';

const Signup = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [signedUp, setSignedUp] = useState(false);
    const history = useNavigate();

    const handleSignup = async () => {
        setErrors({});
        try {
            const response = await axios.post('https://footballcustomformation.com/php_backend/signup.php', {
                username,
                email,
                password,
                confirmPassword
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });

            if (response.data.message === 'User registered successfully') {
                setSignedUp(true);
            } else if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                setErrors({ form: response.data.message });
            }
            console.log('Response errors:', response.data);
        } catch (error) {
            console.error('Signup error', error);
            setErrors({ form: 'Signup failed' });
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSignup();
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        handleSignup();
    };

    if (signedUp) {
        return (
            <div className="signup-container">
                <h2>Thank you for signing up!</h2>
                <p>Make sure to confirm your email before logging in!</p>
                <button className="login-button" onClick={() => history('/login')}>Go To Login</button>
            </div>
        );
    }

    return (
        <div className="signup-container">
            <button className="signup-back-button" onClick={() => history('/')}>
                <IoArrowBack size={24} />
            </button>
            <h2>Sign Up</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Username</label>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyDown={handleKeyPress}
                    />
                    {errors.username && <span className="error">{errors.username}</span>}
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={handleKeyPress}
                    />
                    {errors.email && <span className="error">{errors.email}</span>}
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyPress}
                        onCopy={(e) => e.preventDefault()}
                        onCut={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                    />
                    {errors.password && <span className="error">{errors.password}</span>}
                </div>
                <div className="form-group">
                    <label>Re-enter Password</label>
                    <input
                        type="password"
                        placeholder="Re-enter Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onKeyDown={handleKeyPress}
                        onCopy={(e) => e.preventDefault()}
                        onCut={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                    />
                    {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
                </div>
                <button className="signup-button" type="submit">Sign Up</button>
                {errors.form && <span className="error">{errors.form}</span>}
            </form>
            <div className="login-link">
                <p>Already have an account?</p>
                <Link to="/login">Login</Link>
            </div>
        </div>
    );
};

export default Signup;