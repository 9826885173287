import React, { useState, useEffect } from 'react';
import ReserveSlot from './ReserveSlot';
import './BenchVisualizer.css';

const ReservesVisualizer = ({ reserves, setReserves, removeFromQueue, clearReserves, handleDrop, onStatsChange, pushToQueue, addByPosition }) => {
    const [reservesVisible, setReservesVisible] = useState(false);
    const [cardsPerRow, setCardsPerRow] = useState(5);

    const toggleVisibility = () => setReservesVisible(!reservesVisible);

    useEffect(() => {
        const updateCardsPerRow = () => {
            const screenWidth = window.innerWidth;
            const cards = Math.min(Math.floor(screenWidth / 250), 5);
            setCardsPerRow(cards);
        }

        updateCardsPerRow();
        window.addEventListener('resize', updateCardsPerRow);
    }, [])

    const chunks = reserves.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / cardsPerRow);

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);

    for (let i = reserves.length; i < 18; i++) {
        if (!chunks[Math.floor(i / cardsPerRow)]) {
            chunks[Math.floor(i / cardsPerRow)] = [];
        }
        chunks[Math.floor(i / cardsPerRow)].push(null);
    }

    const nothing = () => {
        return;
    }

    return (
        <div className="bench-visualizer">
            <h2>Reserves (Up to 18 Players) <button className="toggle-bench-btn" onClick={toggleVisibility}>{reservesVisible ? 'Hide' : 'Show'}</button></h2>
            <div className={`bench-container ${reservesVisible ? 'bench-visible' : ''}`}>
                {chunks.map((chunk, chunkIndex) => (
                    <div key={chunkIndex} className="bench-row">
                        {chunk.map((player, playerIndex) => (
                            <ReserveSlot
                                key={playerIndex}
                                player={player}
                                index={playerIndex + cardsPerRow * chunkIndex}
                                handleDrop={handleDrop}
                                setReserves={setReserves}
                                removeFromQueue={removeFromQueue}
                                handleDoubleClickPlayer={nothing}
                                onStatsChange={onStatsChange}
                                pushToQueue={pushToQueue}
                                addByPosition={addByPosition}
                            />
                        ))}
                    </div>
                ))}
            </div>
            {reserves.length > 0 && <button className="button-clear-bench" onClick={() => clearReserves(setReserves)}>Clear Reserves</button>}
        </div>
    );
}

export default ReservesVisualizer