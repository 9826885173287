import React from 'react';
import './aiprediction-popup.css';

const AIPredictionPopup = ({prediction, onClose}) => {
    const parsePrediction = (text) => {
        const parts = text.split(/(\*\*.*?\*\*)/); // Split text by parts enclosed in double asterisks
        return parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                return <b key={index}>{part.slice(2, -2)}</b>; // Wrap bold parts with <b> tags
            } else {
                return <span key={index}>{part}</span>;
            }
        });
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(prediction);
    };

    return (
        <div className="ai-prediction-popup">
            <button className="close-button" onClick={onClose}>X</button>
            <div className="title-container">
                <h2 className="prediction-title">AI Prediction</h2>
            </div>
            <div className="prediction-content">
                {parsePrediction(prediction).map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
            </div>
            <button className="copy-button" onClick={copyToClipboard}>Copy</button>
        </div>
    );
}

export default AIPredictionPopup;