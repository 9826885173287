import React, { useState, useEffect } from 'react';
import Ball from '../resources/soccerball.png';
import Assist from '../resources/assist_symbol.png';
import CleanSheet from '../resources/clean_sheets.png';
import './player-stats-input.css';

const PlayerStatsInput = ({ player, onStatsChange }) => {
    const assistIcon = <img src={Assist} alt="Player Assist" style={{ width: '20px', verticalAlign: 'bottom' }} />;
    const ballIcon = <img src={Ball} alt="Soccer Ball" style={{ width: '20px', verticalAlign: 'bottom' }} />;
    const cleanSheet = <img src={CleanSheet} alt="Clean Sheet" style={{ width: '20px', verticalAlign: 'bottom' }} />;

    const [goals, setGoals] = useState(0);
    const [assists, setAssists] = useState(0);
    const [form, setForm] = useState('Okay');
    const [cleanSheets, setCleanSheets] = useState(0);

    useEffect(() => {
        setGoals(player.goals !== undefined ? player.goals : 0);
        setAssists(player.assists !== undefined ? player.assists : 0);
        setForm(player.form !== undefined ? player.form : 'Okay');
        setCleanSheets(player.cleanSheets !== undefined ? player.cleanSheets : 0);
    }, [player.goals, player.assists, player.form, player.cleanSheets]);

    const handleGoalsChange = (e) => {
        const value = e.target.value === '' ? '' : parseInt(e.target.value, 10);
        setGoals(value);
        if (!isNaN(value)) {
            onStatsChange(player.id, { goals: value, assists, form, cleanSheets }, player.type);
        }
    };

    const handleAssistsChange = (e) => {
        const value = e.target.value === '' ? '' : parseInt(e.target.value, 10);
        setAssists(value);
        if (!isNaN(value)) {
            onStatsChange(player.id, { goals, assists: value, form, cleanSheets }, player.type);
        }
    };

    const handleFormChange = (e) => {
        const value = e.target.value;
        setForm(value);
        onStatsChange(player.id, { goals, assists, form: value, cleanSheets }, player.type);
    };

    const handleCleanSheetsChange = (e) => {
        const value = e.target.value;
        setCleanSheets(value);
        if (!isNaN(value)) {
            onStatsChange(player.id, { goals, assists, form, cleanSheets: value }, player.type);
        }
    }

    return (
        <div className="player-stats-input">
            <div className="stat-input">
                {ballIcon}
                <input
                    type="number"
                    value={goals}
                    onChange={handleGoalsChange}
                    min="0"
                    placeholder="Goals"
                />
            </div>
            <div className="stat-input">
                {assistIcon}
                <input
                    type="number"
                    value={assists}
                    onChange={handleAssistsChange}
                    min="0"
                    placeholder="Assists"
                />
            </div>
            {player.position && (player.position.includes('B') || player.position.includes('GK')) && (
                <div className="stat-input">
                    {cleanSheet}
                    <input
                        type="number"
                        value={cleanSheets}
                        onChange={handleCleanSheetsChange}
                        min="0"
                        placeholder="Clean Sheets"
                    />
                </div>
            )}
            <div className="form-select">
                <label htmlFor="form" style={{color: player.type === 'queue' ? '#fff' : '#000'}}>Form:</label>
                <select id="form" value={form} onChange={handleFormChange}>
                    <option value="Bad">Bad</option>
                    <option value="Okay">Okay</option>
                    <option value="Good">Good</option>
                    <option value="Very Good">Very Good</option>
                    <option value="Excellent">Excellent</option>
                </select>
            </div>
        </div>
    );
};

export default PlayerStatsInput;