import { options } from "../CustomFormation";

const scoringProbabilities = {
    S: 0.25,
    W: 0.2,
    AM: 0.2,
    LM: 0.15,
    RM: 0.15,
    CM: 0.1,
    DM: 0.05,
    LB: 0.03,
    RB: 0.03,
    WB: 0.05,
    CB: 0.02
}

const assistProbabilities = {
    S: 0.15,
    W: 0.2,
    AM: 0.2,
    LM: 0.15,
    RM: 0.15,
    CM: 0.15,
    DM: 0.08,
    LB: 0.06,
    RB: 0.06,
    WB: 0.06,
    CB: 0.03,
    GK: 0.005
}

const tacklingProbabilities = {
    CB: 0.3,
    LB: 0.25,
    RB: 0.25,
    WB: 0.25,
    LM: 0.08,
    RM: 0.08,
    DM: 0.2,
    CM: 0.1,
    AM: 0.05,
    W: 0.03,
    S: 0.02
}

let ratings = {};

const normalizeStat = (stat) => {
    if (stat !== undefined) {
        if (stat.includes('+')) {
            const numbers = stat.split('+');
            const newTotal = parseInt(numbers[0]) + parseInt(numbers[1]);
            return newTotal;
        }

        return parseInt(stat)
    }

    console.error('Stat is undefined double check');
    return 80;
}

const assignCaptain = (team) => {
    const players = Object.values(team.positionsData);

    players.forEach(player => {
        let ageString = player.age;
        if (ageString.includes(';')) {
            ageString = player.age.split(';')[1].trim();
        }
        player.extractedAge = parseInt(ageString, 10);
    });

    players.sort((a, b) => {
        const aScore = Math.pow(a.overall, 1.1) * (1 + a.extractedAge / 100);
        const bScore = Math.pow(b.overall, 1.1) * (1 + b.extractedAge / 100);
        return bScore - aScore;
    });

    team.captain = players[0];
    return players[0];
}

/**
 * Calculates the average ratings for a team's attack,
 * midfield, and defense
 * @param {} team 
 */
const calculateAverageRatings = async (team, formation) => {
    const averages = { attack: 0, midfield: 0, defense: 0 };
    const counts = { attack: 0, midfield: 0, defense: 0 };

    const defaultWeights = {
        attack: formation.strikers + formation.aMidfielders / 2,
        midfield: formation.midfielders + formation.aMidfielders / 2 + formation.dMidfielders / 2,
        defense: formation.defenders + formation.dMidfielders / 2
    };

    for (const player of Object.values(team)) {
        let form = 10.0;
        /** 
        if (player.fotMobLink !== '0') {
            await fetchPlayerForm(player);
            if (player.realStats) {
                form = player.realStats.form;
                console.log(player.name, 'form', form);
            }
        } else {
            await fetchFotMobID(player);
            if (player.realStats) {
                form = player.realStats.form;
                console.log(player.name, 'form', form);
            }
        } */

        const effectiveRating = Math.pow((parseInt(player.overall)) + ((normalizeStat(player[player.currentPosition.name])) / 2), 1.1) * (form / 10.0);
        if (player.currentPosition.key.includes('Striker') || player.currentPosition.key.includes('aMidfielder')) {
            let weight = 1.0;
            if (player.currentPosition.key.includes('aMidfielder')) {
                weight = .5;
            }
            averages.attack += effectiveRating * weight;
            counts.attack += weight;
        }
        if (player.currentPosition.key.includes('idfielder')) {
            let weight = 1.0;
            if (player.currentPosition.key.includes('aMidfielder') || player.currentPosition.key.includes('dMidfielder')) {
                if (formation.aMidfielders !== 0) {
                    weight = 0.5;
                }
            }
            averages.midfield += effectiveRating * weight;
            counts.midfield += weight;
        }
        if (player.currentPosition.key.includes('Defender') || player.currentPosition.key === 'Goalkeeper-0' || player.currentPosition.key.includes('dMidfielder')) {
            let weight = 1.0;
            if (player.currentPosition.key.includes('dMidfielder')) {
                weight = .5;
            }
            averages.defense += effectiveRating * weight;
            counts.defense += weight;
        }
    }

    const adjustScore = (total, count, defaultWeight) => {
        if (count < defaultWeight) {
            const penaltyFactor = count / defaultWeight; // Calculate the penalty factor
            return (total / count) * penaltyFactor; // Apply the penalty to the total score
        } else {
            return total / count; // Return the normal average if count is not lower
        }
    };


    return {
        attack: adjustScore(averages.attack, counts.attack, defaultWeights.attack),
        midfield: adjustScore(averages.midfield, counts.midfield, defaultWeights.midfield),
        defense: adjustScore(averages.defense, counts.defense, defaultWeights.defense)
    };
};

/**
 * Calculates the strength of a formation
 * @param {*} formation 
 * @returns the attack score and defense score of a formation
 */
const getFormationStrength = (formation) => {
    let strikerWeight = formation.strikers;
    if (formation.strikers === 3) {
        strikerWeight = 2.5; // Account for 2 wingers and 1 striker
    }

    const attackScore = (strikerWeight * 3) + (formation.aMidfielders * 2.5) + formation.midfielders;
    const defenseScore = (formation.defenders * 3) + (formation.dMidfielders * 2.5) + formation.midfielders;

    const midfieldScore = formation.midfielders + formation.dMidfielders + formation.aMidfielders * .8;
    return { attackScore, defenseScore, midfieldScore };
}

const calculateOverallStrength = (strength) => {
    return (strength.attack + strength.midfield + strength.defense) / 3;
}

const applyFormationUsesBoosts = (formation, strength) => {
    const formationString = Object.values(formation).join('-');
    const formationData = options.find(opt => opt.value === formationString);

    //console.log('Formation uses:', formationData);

    if (formationData) {
        const uses = formationData.uses;

        // First use (higher weight)
        if (uses[0] === 'Attacking') {
            strength.attackScore *= 1.3; // Higher boost for first use
        } else if (uses[0] === 'Defending') {
            strength.defenseScore *= 1.3;
        } else if (uses[0] === 'Possession') {
            strength.midfieldScore *= 1.2;
        } else if (uses[0] === 'Counterattacking') {
            strength.attackScore *= 1.2;
            strength.defenseScore *= 1.2;
        } else if (uses[0] === 'Midfield Control') {
            strength.midfieldScore *= 1.35;
        }

        // Second use (lower weight)
        if (uses[1] === 'Attacking') {
            strength.attackScore *= 1.1; // Slightly lower boost for second use
        } else if (uses[1] === 'Defending') {
            strength.defenseScore *= 1.1;
        } else if (uses[1] === 'Possession') {
            strength.midfieldScore *= 1.1;
        } else if (uses[1] === 'Counterattacking') {
            strength.attackScore *= 1.1;
            strength.defenseScore *= 1.1;
        } else if (uses[1] === 'Midfield Control') {
            strength.midfieldScore *= 1.2;
        }
    }

    return strength;
}

const getFormationBoost = (formation1, formation2, team1Strength, team2Strength) => {
    const team1 = getFormationStrength(formation1);
    const team2 = getFormationStrength(formation2);

    const attackDifference = team1.attackScore - team2.defenseScore;
    const defenseDifference = team1.defenseScore - team2.attackScore;
    const midfieldDifference = team1.midfieldScore - team2.midfieldScore;

    const team1OverallStrength = calculateOverallStrength(team1Strength);
    const team2OverallStrength = calculateOverallStrength(team2Strength);

    let team1Boost = (attackDifference + defenseDifference + midfieldDifference);
    let team2Boost = -(attackDifference + defenseDifference + midfieldDifference);
    //console.log('Team1 Boost', team1Boost, '\nTeam2 Boost', team2Boost);

    if (team1OverallStrength < team2OverallStrength) {
        team1Boost *= 2;
    } else if (team2OverallStrength < team1OverallStrength) {
        team2Boost *= 2;
    }

    return { team1Boost, team2Boost };
};

/**
 * Function that calculates the chance the player scores based on their position
 * and skills
 * @param {*} player 
 */
const calculatePlayerScoringProbability = (player) => {
    if (player.currentPosition.name === 'GK') {
        return 0.001;
    }
    const stats = ['shooting', 'longShots', 'shotPower', 'positioning', 'composure', 'volleys', 'ballControl', 'headingAccuracy'];

    const totalWeight = stats.length * (stats.length + 1) / 2; // Sum of first N natural numbers (1+2+3+...+N)

    let weightedSum = stats.reduce((sum, stat, index) => {
        const weight = stats.length - index; // Higher weights for earlier stats
        return sum + (parseInt(player[stat]) * weight);
    }, 0);

    let weightedAverage = weightedSum / totalWeight;

    const positionKey = Object.keys(scoringProbabilities).find(key => player.currentPosition.name.includes(key));
    const positionProbability = scoringProbabilities[positionKey] || 0.01;

    //console.log(`${player.name} prob of scoring: ${averageStat * positionProbability}`);
    return weightedAverage * positionProbability;
}

/**
 * Function that calculates the chance the player assists based on their position
 * and skills
 * @param {*} player 
 */
const calculatePlayerAssistingProbability = (player) => {
    const stats = ['skill_dribbling', 'crossing', 'passing', 'vision', 'jumping', 'shortPassing', 'longPassing'];
    const weights = [0.15, 0.1, 0.2, 0.15, 0.1, 0.2, 0.1];
    let weightedStatSum = stats.reduce((sum, stat, index) => sum + (parseInt(player[stat]) * weights[index]), 0);
    const positionKey = Object.keys(assistProbabilities).find(key => player.currentPosition.name.includes(key));
    const positionProbability = assistProbabilities[positionKey] || 0.01;
    //console.log(`Assist probability for ${player.name}: ${weightedStatSum * positionProbability}`);
    return weightedStatSum * positionProbability;
}

const determineAssister = (team, scorer) => {
    if (Math.random() < 0.05) {
        return '';
    }
    const players = Object.values(team.positionsData).filter(player => player.name !== scorer);
    const probabilities = players.map(player => calculatePlayerAssistingProbability(player));
    const totalProbability = probabilities.reduce((sum, prob) => sum + prob, 0);
    const randomValue = Math.random() * totalProbability;
    let cumulativeProbability = 0;

    for (let i = 0; i < players.length; i++) {
        cumulativeProbability += probabilities[i];
        if (randomValue < cumulativeProbability) {
            return players[i].name;
        }
    }

    return 'No assister';
};

const determineGoalScorer = (team, opposingTeam) => {
    const players = Object.values(team.positionsData);
    const probabilities = players.map(player => calculatePlayerScoringProbability(player));
    const totalProbability = probabilities.reduce((sum, prob) => sum + prob, 0);
    const randomValue = Math.random() * totalProbability;
    let cumulativeProbability = 0;

    for (let i = 0; i < players.length; i++) {
        cumulativeProbability += probabilities[i];
        if (Math.random() < 0.01) {
            const opposingPlayers = Object.values(opposingTeam.positionsData);
            const randomOpponent = opposingPlayers[Math.floor(Math.random() * opposingPlayers.length)];
            return { scorer: `${randomOpponent.name} (o.g.)`, assister: '' };
        }

        if (randomValue < cumulativeProbability) {
            const assister = determineAssister(team, players[i].name);
            return { scorer: players[i].name, assister };
        }
    }

    return 'error';
}

/**
 * Determines whether a tackle from a player is successful or results in foul
 * @param {*} player 
 * @returns weightedSum of defensive stats of player
 */
const calculateTackleSuccess = (player) => {
    const stats = { marking: 0.2, standingTackle: 0.5, slidingTackle: 0.3, strength: 0.1 };
    const weightedSum = Object.keys(stats).reduce((sum, stat) => {
        return sum + parseInt(player[stat]) / 100 * stats[stat];
    }, 0);
    //console.log(`${player.name} tackling prob: ${weightedSum}`);
    return weightedSum;
}

const determineTackler = (team) => {
    const players = Object.values(team.positionsData);
    const probabilities = players.map(player => {
        const positionKey = Object.keys(tacklingProbabilities).find(key => player.currentPosition.name.includes(key));
        const positionProb = tacklingProbabilities[positionKey] || 0.01;
        return positionProb;
    });
    const totalProbability = probabilities.reduce((sum, prob) => sum + prob, 0);
    const randomValue = Math.random() * totalProbability;
    let cumulativeProbability = 0;

    for (let i = 0; i < players.length; i++) {
        cumulativeProbability += probabilities[i];
        if (randomValue < cumulativeProbability) {
            return players[i];
        }
    }

    return null;
}

const checkForFoul = (tackler, fouledTeam) => {
    const successProb = calculateTackleSuccess(tackler);
    //console.log('Tackle probability', successProb);
    const randomValue = Math.random();

    if (randomValue < successProb) {
        return { foul: false };
    }

    //Foul occurred
    const foulDetails = {
        foul: true,
        penalty: false,
        yellowCard: false,
        redCard: false,
        fouledPlayer: null,
        tacklerName: tackler.name,
        fouledPlayerName: null,
        penaltyTaker: null
    };
    const aggression = parseInt(tackler.aggression) / 100;
    const fouledPlayers = Object.values(fouledTeam.positionsData);
    //Chooses randomlyFouledPlayer
    const randomlyFouledPlayer = fouledPlayers[Math.floor(Math.random() * fouledPlayers.length)];
    foulDetails.fouledPlayer = randomlyFouledPlayer.name;

    if (Math.random() < aggression * 0.15) {
        foulDetails.yellowCard = true;
    }

    if (Math.random() < aggression * 0.01 && !foulDetails.yellowCard) {
        foulDetails.redCard = true;
    }

    if (Math.random() < 0.015) {
        foulDetails.penalty = true;
        const players = Object.values(fouledTeam.positionsData);
        let bestPenaltyTaker = players.reduce((best, player) => {
            let playerPenalties = parseInt(player.penalties);

            if (!best) {
                return player;
            }
            if (!best || parseInt(player.penalties) > parseInt(best.penalties)) {
                return player;
            }
            let bestPenalties = parseInt(best.penalties);

            // Apply a 15% boost if the player is the captain
            if (fouledTeam.captain && player.name === fouledTeam.captain.name) {
                playerPenalties *= 1.1;
            }

            // Apply a 15% boost if the best player so far is the captain
            if (fouledTeam.captain && best && best.name === fouledTeam.captain.name) {
                bestPenalties *= 1.1;
            }
            return playerPenalties > bestPenalties ? player : best;
        }, null);

        //console.log(`Penalties stat for ${bestPenaltyTaker.name}: ${bestPenaltyTaker.penalties}`);
        foulDetails.penaltyTaker = bestPenaltyTaker.name;
    }

    return foulDetails;
}

/**
 * Player takes penalty and scores based on their penalty stat
 * @param {*} penaltyStat 
 * @returns true or false if penalty is scored
 */
const takePenalty = (penaltyStat, isCaptain) => {
    let success = penaltyStat / 100;
    if (isCaptain) success = Math.min(success * 1.1, 1);
    return Math.random() < success;
}

/**
 * Player is substituted with the best possible sub from the bench
 * @param {*} position that will will be replaced 
 * @param {*} bench 
 * @returns the best substitute given player positioin
 */
const getBestSubstitute = (position, bench) => {
    // Ensure bench is an array before sorting
    const benchArray = Array.isArray(bench) ? bench : Object.values(bench);

    if (Array.isArray(benchArray)) {
        const benchSorted = benchArray.sort((a, b) => {
            const getStatValue = (player) => {
                const statValue = player[position];

                // Split the stat value into base and increment parts
                const parts = statValue.split('+');
                let base = parseInt(parts[0], 10);
                const increment = parts.length > 1 ? parseInt(parts[1], 10) : 0;

                if (player.position.includes(position)) {
                    base += 1000;
                }
                return base + increment;
            };

            const aValue = getStatValue(a);
            const bValue = getStatValue(b);

            return bValue - aValue;
        });

        //console.log('Sorted bench:', benchSorted);
        return benchSorted[0];
    } else {
        console.error('Bench is not an array:', bench);
    }
}

/**
 * If team1 and team2 have different number of players, players
 * get decreased to make team1 equal to team2
 * @param {*} team1Penalties 
 * @param {*} team2Penalties 
 */
const adjustPenaltyTakers = (team1Penalties, team2Penalties) => {
    if (team1Penalties.length > team2Penalties.length) {
        // Reduce team1's penalty takers
        team1Penalties.sort((a, b) => parseInt(a.penalties) - parseInt(b.penalties));
        while (team1Penalties.length > team2Penalties.length) {
            team1Penalties.pop();
        }
    } else if (team2Penalties.length > team1Penalties.length) {
        // Reduce team2's penalty takers
        team2Penalties.sort((a, b) => parseInt(a.penalties) - parseInt(b.penalties));
        while (team2Penalties.length > team1Penalties.length) {
            team2Penalties.pop();
        }
    }

    // Sort both lists in descending order
    team1Penalties.sort((a, b) => parseInt(b.penalties) - parseInt(a.penalties));
    team2Penalties.sort((a, b) => parseInt(b.penalties) - parseInt(a.penalties));
};

const simulatePenalty = (penaltyStat, goalkeeper) => {
    let weightedSum = 50;
    const stats = ['gkDiving', 'gkHandling', 'gkPos', 'gkReflex', 'reactions'];
    const hasNullStats = stats.some(stat => goalkeeper[stat] == null);

    if (!hasNullStats) {
        weightedSum = goalkeeper.gkDiving * 0.2 + goalkeeper.gkHandling * 0.15 + goalkeeper.gkPos * 0.2 + goalkeeper.gkReflex * 0.25 +
            goalkeeper.reactions * 0.2;
    }

    const statDifference = penaltyStat - weightedSum;
    let baseProbability = penaltyStat / 100;

    if (statDifference > 0) {
        baseProbability += statDifference / 200;
    } else {
        baseProbability -= statDifference / 300;
    }

    //console.log(`Base penalty probability: ${Math.max(0, Math.min(baseProbability, 1))}`);

    return Math.random() < Math.max(0, Math.min(baseProbability, 1));
}

/**
 * Simulates a penalty shootout 
 * @param {*} team1 
 * @param {*} team2 
 * @returns team1 and team2's penalty score
 */
const simulatePenalties = async (team1, team2) => {
    const team1Penalties = Object.values(team1.positionsData)
        .filter(player => player.penalties)
        .sort((a, b) => parseInt(b.penalties) - parseInt(a.penalties));

    const team2Penalties = Object.values(team2.positionsData)
        .filter(player => player.penalties)
        .sort((a, b) => parseInt(b.penalties) - parseInt(a.penalties));

    const goalkeeper1 = team1.positionsData['Goalkeeper-0'];
    const goalkeeper2 = team2.positionsData['Goalkeeper-0'];

    adjustPenaltyTakers(team1Penalties, team2Penalties);
    //console.log('Penalty takers: ', team1Penalties, team2Penalties);

    const maxPenalties = 5;
    let team1Score = 0;
    let team2Score = 0;
    let team1Taken = 0;
    let team2Taken = 0;
    let penaltyLog = [];

    const firstTeam = Math.random() < 0.5 ? 'team1' : 'team2';
    let currentTeam = firstTeam;

    //Simulation of first five penalties
    while (team1Taken < maxPenalties || team2Taken < maxPenalties) {
        if (currentTeam === 'team1' && team1Taken < maxPenalties) {
            const team1Taker = team1Penalties[team1Taken % team1Penalties.length];
            const scored = simulatePenalty(parseInt(team1Taker.penalties), goalkeeper2);
            if (scored) {
                ratings[team1Taker.name].rating = Math.min(10, ratings[team1Taker.name].rating + 0.5);
            } else {
                ratings[team1Taker.name].rating = Math.max(0, ratings[team1Taker.name].rating - 0.2);
                ratings[goalkeeper2.name].rating = Math.min(10, ratings[goalkeeper2.name].rating + 0.5);
            }
            team1Score += scored ? 1 : 0;
            penaltyLog.push({ team: team1.displayName, name: team1Taker.name, scored: scored });
            //console.log(`${team1.displayName}: ${team1Taker.name} ${scored ? 'scored' : 'missed'} his penalty!`);
            team1Taken++;
            currentTeam = 'team2';
        } else if (currentTeam === 'team2' && team2Taken < maxPenalties) {
            const team2Taker = team2Penalties[team2Taken % team2Penalties.length];
            const scored = simulatePenalty(parseInt(team2Taker.penalties), goalkeeper1);
            if (scored) {
                ratings[team2Taker.name].rating = Math.min(10, ratings[team2Taker.name].rating + 0.5);
            } else {
                ratings[team2Taker.name].rating = Math.max(0, ratings[team2Taker.name].rating - 0.2);
                ratings[goalkeeper1.name].rating = Math.min(10, ratings[goalkeeper1.name].rating + 0.5);
            }
            team2Score += scored ? 1 : 0;
            penaltyLog.push({ team: team2.displayName, name: team2Taker.name, scored: scored });
            //console.log(`${team2.displayName}: ${team2Taker.name} ${scored ? 'scored' : 'missed'} his penalty!`);
            team2Taken++;
            currentTeam = 'team1';
        }

        if ((team1Score > team2Score + (maxPenalties - team2Taken)) || (team2Score > team1Score + (maxPenalties - team1Taken))) {
            break;
        }
    }

    let suddenDeath = team1Score === team2Score;
    let penaltyIndex = maxPenalties;
    while (suddenDeath) {
        const team1Taker = team1Penalties[penaltyIndex % team1Penalties.length];
        const team2Taker = team2Penalties[penaltyIndex % team2Penalties.length];

        const team1Scored = simulatePenalty(parseInt(team1Taker.penalties), goalkeeper2);
        const team2Scored = simulatePenalty(parseInt(team2Taker.penalties), goalkeeper1);

        penaltyLog.push({ team: team1.displayName, name: team1Taker.name, scored: team1Scored });
        console.log(`${team1.displayName}: ${team1Taker.name} ${team1Scored ? 'scored' : 'missed'} his penalty!`);
        penaltyLog.push({ team: team2.displayName, name: team2Taker.name, scored: team2Scored });
        console.log(`${team2.displayName}: ${team2Taker.name} ${team2Scored ? 'scored' : 'missed'} his penalty!`);

        if (team1Scored && !team2Scored) {
            team1Score++;
            break;
        } else if (!team1Scored && team2Scored) {
            team2Score++;
            break;
        } else if (team1Scored && team2Scored) {
            team1Score++;
            team2Score++;
        }

        penaltyIndex++;
    }

    return { team1Score, team2Score, penaltyLog };
}

const simulateMatch = async (team1, team2, homeTeam, isCup) => {
    //Count yellowCards
    const yellowCardCount = {};
    const removedPlayers = { team1: [], team2: [] };
    let goalScorers = [];
    const substitutions = { team1: [], team2: [] };
    const substitutionTracker = { team1: [], team2: [] };
    const missedPenalties = [];
    const shots = {};
    ratings = {};

    Object.keys(team1.positionsData).forEach(key => {
        const playerName = team1.positionsData[key].name;
        ratings[playerName] = { team: team1.displayName, rating: 6.0 };
    });

    Object.keys(team2.positionsData).forEach(key => {
        const playerName = team2.positionsData[key].name;
        ratings[playerName] = { team: team2.displayName, rating: 6.0 };
    });

    const removePlayer = async (team, playerName) => {
        //console.log(`${playerName} to be removed from ${team.displayName}`);
        const teamKey = team.displayName === team1.displayName ? 'team1' : 'team2';
        const newPositionsData = Object.fromEntries(
            Object.entries(team.positionsData).filter(([key, player]) => {
                if (player.name === playerName) {
                    // Save the removed player for further use if needed
                    removedPlayers[teamKey][key] = player;
                    return false; // Exclude this entry
                }
                return true; // Include this entry
            })
        );

        // Update the team's positionsData with the new object
        team.positionsData = newPositionsData;
    }; //removePlayer

    const calculateScoringProbability = (team1Effectiveness, team2Effectiveness) => {
        // Team 1 scoring probability
        const attackVsDefense1 = (team1Effectiveness.attack + team1Effectiveness.midfield) - (team2Effectiveness.defense + (team2Effectiveness.midfield / 2));

        // Team 2 scoring probability
        const attackVsDefense2 = (team2Effectiveness.attack + team2Effectiveness.midfield) - (team1Effectiveness.defense + (team1Effectiveness.midfield / 2));

        // Logistic function to calculate scoring probabilities
        // Normalize the values to a range of 0 to 1
        const minScore = 15; // minimum effective score difference observed
        const maxScore = 200; // maximum effective score difference observed

        const normalize = (value) => {
            return (value - minScore) / (maxScore - minScore);
        };

        const team1ScoreProb = normalize(attackVsDefense1);
        const team2ScoreProb = normalize(attackVsDefense2);
        return { team1ScoreProb, team2ScoreProb };
    }

    const calculateTeamEffectiveness = async (isFirst) => {
        const checkAndAssignCaptain = (team) => {
            const captainExistsInPositions = Object.values(team.positionsData).some(
                (player) => player && player.name === team.captain.name
            );

            if (!captainExistsInPositions) {
                team.captain = assignCaptain(team);
            }
        }

        if (!isFirst) {
            checkAndAssignCaptain(team1);
            checkAndAssignCaptain(team2);
        }
        const team1Strength = await calculateAverageRatings(team1.positionsData, team1.formation);
        const team2Strength = await calculateAverageRatings(team2.positionsData, team2.formation);

        //console.log('Team 1 Strength:', team1Strength, 'Team 2 Strength:', team2Strength);

        const team1Effectiveness = {
            attack: team1Strength.attack + formationBoosts.team1Boost,
            midfield: team1Strength.midfield + formationBoosts.team1Boost,
            defense: team1Strength.defense + formationBoosts.team1Boost
        };

        const team2Effectiveness = {
            attack: team2Strength.attack + formationBoosts.team2Boost,
            midfield: team2Strength.midfield + formationBoosts.team2Boost,
            defense: team2Strength.defense + formationBoosts.team2Boost
        };

        //console.log('Team 1 Effectiveness:', team1Effectiveness, '\nTeam 2 Effectiveness:', team2Effectiveness);

        const scoringProbabilities = calculateScoringProbability(team1Effectiveness, team2Effectiveness);
        let team1ScoreProb = (scoringProbabilities.team1ScoreProb + (homeTeam === 'team1' ? 0.1 : 0));
        let team2ScoreProb = (scoringProbabilities.team2ScoreProb);

        //console.log('Team 1 Score Prob', team1ScoreProb.toFixed(2), '\nTeam 2 Score Prob', team2ScoreProb.toFixed(2));

        return { team1Effectiveness, team2Effectiveness, team1ScoreProb, team2ScoreProb };
    };

    let team1Strength = await calculateAverageRatings(team1.positionsData, team1.formation);
    let team2Strength = await calculateAverageRatings(team2.positionsData, team2.formation);
    const formationBoosts = getFormationBoost(team1.formation, team2.formation, team1Strength, team2Strength);
    let { team1Effectiveness, team2Effectiveness, team1ScoreProb, team2ScoreProb } = await calculateTeamEffectiveness(true);

    //console.log('Team 1 Score:', team1Effectiveness, '\nTeam 2 Score:', team2Effectiveness);

    let team1Goals = 0, team2Goals = 0, fouls = 0;
    let yellowCards = [], redCards = [];
    let penalties;
    let oldPosition = null;

    //console.log('Team 1 Score Prob', team1ScoreProb.toFixed(2), '\nTeam 2 Score Prob', team2ScoreProb.toFixed(2));

    const reinstateRemovedPlayers = () => {
        for (const [key, player] of Object.entries(removedPlayers.team1)) {
            if (oldPosition) {
                if (player.name === oldPosition.name) {
                    player.currentPosition = oldPosition.position;
                }
            }
            team1.positionsData[key] = player;
        }
        for (const [key, player] of Object.entries(removedPlayers.team2)) {
            if (oldPosition) {
                if (player.name === oldPosition.name) {
                    player.currentPosition = oldPosition.position;
                }
            }
            team2.positionsData[key] = player;
        }

        for (let i = substitutionTracker.team1.length - 1; i >= 0; i--) {
            const sub = substitutionTracker.team1[i];
            // Add the substituted player back to the bench
            team1.bench.push(team1.positionsData[sub.key]);
            // Put the original player back to the starting position
            team1.positionsData[sub.player.currentPosition.key] = sub.player;
            // Remove the sub from the substitution tracker
            substitutionTracker.team1.splice(i, 1);
        }

        // Handle substitutions for team2
        for (let i = substitutionTracker.team2.length - 1; i >= 0; i--) {
            const sub = substitutionTracker.team2[i];
            // Add the substituted player back to the bench
            team2.bench.push(team2.positionsData[sub.key]);
            // Put the original player back to the starting position
            team2.positionsData[sub.player.currentPosition.key] = sub.player;
            // Remove the sub from the substitution tracker
            substitutionTracker.team2.splice(i, 1);
        }
    };

    const replaceGoalkeeper = async (team, numSubstitutions, maxSubstitutions) => {
        //Find substitute goalkeeper
        const subGoalkeeper = team.bench.find(player => player.position === 'GK');
        console.log('subGoalkeeper', subGoalkeeper);
        if (numSubstitutions < maxSubstitutions && subGoalkeeper) {
            const fieldPlayers = Object.entries(team.positionsData).filter(
                ([key]) => key.startsWith('aMidfielder') || key.startsWith('Striker')
            );
            if (fieldPlayers.length > 0) {
                const playerToReplace = fieldPlayers.reduce((lowest, current) => {
                    return current[1].overall < lowest[1].overall ? current : lowest;
                })

                //console.log('Player to replace', playerToReplace);
                const [bestPositionKey, bestPlayerInfo] = playerToReplace;
                subGoalkeeper.currentPosition = { key: 'Goalkeeper-0', name: 'GK' };
                team.positionsData['Goalkeeper-0'] = subGoalkeeper;
                team.bench = team.bench.filter(player => player.name !== subGoalkeeper.name);
                delete team.positionsData[bestPositionKey];
                //console.log('Remaining players', team.positionsData);
                return { bestPlayerInfo, subGoalkeeper, bestPositionKey };
            }
        } else {
            //Case if there are too many substitutions
            const remainingPlayers = Object.entries(team.positionsData);

            const parseHeight = (heightString) => {
                const match = heightString.match(/\b(\d{3})cm\b/);
                return match ? parseInt(match[1], 10) : 0;
            }

            const calculateGoalkeeperScore = (player) => {
                const height = parseHeight(player.height || '1cm');
                const reactions = parseInt(player.reactions);
                const gkDiving = player.gkDiving !== undefined ? player.gkDiving : 10;
                return 0.6 * height + 0.3 * reactions + 0.1 * gkDiving;
            }

            const bestGoalkeeper = remainingPlayers.reduce((best, current) => {
                const currentPlayer = current[1];
                const currentScore = calculateGoalkeeperScore(currentPlayer);
                const bestScore = calculateGoalkeeperScore(currentPlayer);
                return currentScore > bestScore ? current : best;
            });

            console.log('Best goalkeeper:', bestGoalkeeper);

            const [bestPositionKey, bestPlayerInfo] = bestGoalkeeper;

            oldPosition = { name: bestPlayerInfo.name, position: bestPlayerInfo.currentPosition };
            bestPlayerInfo.currentPosition = { key: 'Goalkeeper-0', name: 'GK' };
            removePlayer(team, team.positionsData[bestPositionKey].name); //This works
            console.log('Removed players', removedPlayers);
            team.positionsData['Goalkeeper-0'] = bestPlayerInfo; //Doesn't work for some reason

            return { bestPlayerInfo: null, subGoalkeeper: null, bestPositionKey };
        }

    };

    const getRatingForShot = (scorer) => {
        if (scorer.includes('o.g.')) {
            return;
        }
        const randomChange = (Math.random() * 0.4) - 0.2;
        ratings[scorer].rating = Math.max(0, Math.min(10, ratings[scorer].rating + randomChange));
    }

    const getRatingForShotOnTarget = (scorer) => {
        if (scorer.includes('o.g.')) {
            return;
        }
        const randomChange = (Math.random() * 0.3);
        ratings[scorer].rating = Math.min(10, ratings[scorer].rating + randomChange);
    };

    const updateShots = (scorer, team, onTarget, type = 'shot') => {
        const scorerKey = `${scorer}+${team}`;
        if (!shots[scorerKey]) {
            shots[scorerKey] = {
                name: scorer,
                type,
                shots: 0,
                shotsOnTarget: 0
            };
        }
        shots[scorerKey].shots++;
        if (onTarget) {
            shots[scorerKey].shotsOnTarget++;
        }
    }

    const playMinutes = async (startMinute, endMinute, maxStoppageTime) => {
        const maxSubstitutions = startMinute > 90 ? 6 : 5;
        for (let minute = startMinute; minute <= endMinute + maxStoppageTime; minute++) {
            //Allows for stoppage time
            let displayMinute;
            if (minute <= endMinute) {
                displayMinute = minute;
            } else {
                displayMinute = `${endMinute}+${minute - endMinute}`;
            }

            //Substitution logic
            if (minute > 45 + maxStoppageTime || minute > 105 + maxStoppageTime) {
                const teamToSubstitute = minute % 2 === 0 ? team1 : team2;
                const teamKey = teamToSubstitute === team1 ? 'team1' : 'team2';

                for (const [key, player] of Object.entries(teamToSubstitute.positionsData)) {
                    const stamina = parseInt(player.stamina);
                    //Substitution probability to be updated
                    let subLikelihood = (100 - stamina) / 900;
                    if (key === 'Goalkeeper-0') {
                        const hasGoalkeeper = teamToSubstitute.bench.some(player => player.position.includes('GK'));
                        if (hasGoalkeeper) {
                            subLikelihood /= 50;
                        } else {
                            subLikelihood = 0;
                        }
                    }

                    const alreadySubstituted = substitutions[teamKey].some(sub => sub.substitute === player.name);

                    if (Math.random() < subLikelihood && Object.keys(substitutions[teamKey]).length < maxSubstitutions && !alreadySubstituted) {
                        const currentPosition = player.currentPosition;
                        const benchArray = Array.isArray(teamToSubstitute.bench) ? teamToSubstitute.bench : Object.values(teamToSubstitute.bench);
                        let bestSubstitute = getBestSubstitute(player.currentPosition.name, benchArray);

                        //console.log(`Best sub for ${player.name}: ${bestSubstitute.name}`);
                        if (currentPosition) {
                            bestSubstitute.currentPosition = currentPosition;
                        }
                        if (bestSubstitute && bestSubstitute.currentPosition) {
                            substitutionTracker[teamKey].push({ key, player: teamToSubstitute.positionsData[key] });
                            teamToSubstitute.positionsData[key] = bestSubstitute;
                            //console.log('Team To Substitute bench:', teamToSubstitute.bench);
                            teamToSubstitute.bench = benchArray.filter(sub => sub.name !== bestSubstitute.name);
                            substitutions[teamKey].push({ substitutedPlayer: player.name, substitute: bestSubstitute.name, minute: displayMinute });
                            ratings[bestSubstitute.name] = { team: teamToSubstitute.displayName, rating: 6.0 };
                            //console.log(`${player.name} is substituted by ${bestSubstitute.name} in ${displayMinute}'`);
                            //console.log('New Positions', teamToSubstitute.positionsData);
                            const updatedEffectiveness = await calculateTeamEffectiveness(false);
                            team1Effectiveness = updatedEffectiveness.team1Effectiveness;
                            team2Effectiveness = updatedEffectiveness.team2Effectiveness;
                            team1ScoreProb = updatedEffectiveness.team1ScoreProb;
                            team2ScoreProb = updatedEffectiveness.team2ScoreProb;
                        }
                    }
                }
            }

            //Tackle check
            const tackler = determineTackler(minute % 2 === 0 ? team1 : team2);
            const fouledTeam = minute % 2 === 0 ? team2 : team1;
            const foulDetails = checkForFoul(tackler, fouledTeam);
            //console.log('Foul details', foulDetails);

            if (!foulDetails.foul) {
                //Works
                ratings[tackler.name].rating = Math.min(10, ratings[tackler.name].rating + 0.1);
            }

            if (foulDetails.foul) {
                ratings[foulDetails.tacklerName].rating = Math.max(0, ratings[foulDetails.tacklerName].rating - 0.3);
                fouls++;
                let isSentOff = false;
                if (foulDetails.yellowCard) {
                    if (foulDetails.yellowCard) {
                        if (!yellowCardCount[foulDetails.tacklerName]) {
                            yellowCardCount[foulDetails.tacklerName] = 0;
                        }
                        ratings[foulDetails.tacklerName].rating = Math.max(0, ratings[foulDetails.tacklerName].rating - 1);
                    }
                    yellowCardCount[foulDetails.tacklerName]++;
                    yellowCards.push({ team: minute % 2 === 0 ? team1.displayName : team2.displayName, name: foulDetails.tacklerName, fouledPlayer: foulDetails.fouledPlayer, minute: displayMinute });
                    //console.log(`${foulDetails.tacklerName} received a yellow card for foul on ${foulDetails.fouledPlayer} in ${displayMinute}'`);

                    if (yellowCardCount[foulDetails.tacklerName] === 2) {
                        ratings[foulDetails.tacklerName].rating = Math.max(0, ratings[foulDetails.tacklerName].rating - 0.8);
                        isSentOff = true;
                        //console.log(`${foulDetails.tacklerName} has been sent off with a second yellow card.`);
                    }
                }
                if (foulDetails.redCard) {
                    redCards.push({ team: minute % 2 === 0 ? team1.displayName : team2.displayName, name: foulDetails.tacklerName, fouledPlayer: foulDetails.fouledPlayer, minute: displayMinute });
                    isSentOff = true;
                    ratings[foulDetails.tacklerName].rating = Math.max(0, ratings[foulDetails.tacklerName].rating - 2.5);
                    //console.log(`${foulDetails.tacklerName} received a red card for foul on ${foulDetails.fouledPlayer} in ${displayMinute}'`);
                }
                //Case for if foul results in Penalty
                if (foulDetails.penalty) {
                    const scorer = foulDetails.penaltyTaker;
                    const penaltyTaker = Object.values(fouledTeam.positionsData).find(player => player.name === scorer);
                    if (penaltyTaker && takePenalty(parseInt(penaltyTaker.penalties), fouledTeam.captain.name === penaltyTaker.name)) {
                        if (minute % 2 === 0) {
                            team2Goals++;
                            goalScorers.push({ team: team2.displayName, name: scorer, assister: '', minute: displayMinute + ' (P)' });
                            updateShots(penaltyTaker.name, team2.displayName, true);
                            //console.log(`Penalty scored by ${scorer} for ${team2.displayName} in ${displayMinute}' ${team1Goals}-${team2Goals}!`);
                        } else {
                            team1Goals++;
                            goalScorers.push({ team: team1.displayName, name: scorer, assister: '', minute: displayMinute + ' (P)' });
                            updateShots(penaltyTaker.name, team1.displayName, true);
                            //console.log(`Penalty scored by ${scorer} for ${team1.displayName} in ${displayMinute}' ${team1Goals}-${team2Goals}!`);
                        }
                        ratings[penaltyTaker.name].rating = Math.min(10, ratings[penaltyTaker.name].rating + 1.5);
                    } else {
                        const penaltySaved = Math.random();
                        const penaltyTeam = minute % 2 === 0 ? team2.displayName : team1.displayName;
                        const opposingTeam = penaltyTeam === team2.displayName ? team1.displayName : team2.displayName;
                        const opposingGoalkeeper = minute % 2 === 0 ? team1.positionsData['Goalkeeper-0'].name : team2.positionsData['Goalkeeper-0'].name;
                        if (penaltySaved < 0.6) {
                            //console.log(`Penalty was saved by ${opposingGoalkeeper} in ${displayMinute}'`);
                            ratings[opposingGoalkeeper].rating = Math.min(10, ratings[opposingGoalkeeper].rating + 1);
                            updateShots(opposingGoalkeeper, opposingTeam, false, 'save');
                            ratings[penaltyTaker.name].rating = Math.max(0, ratings[penaltyTaker.name].rating - 0.2);
                            updateShots(penaltyTaker.name, penaltyTeam, true);
                        } else {
                            //console.log(`Penalty missed by ${scorer} in ${displayMinute}'`);
                            ratings[penaltyTaker.name].rating = Math.max(0, ratings[penaltyTaker.name].rating - 0.4);
                            updateShots(penaltyTaker.name, penaltyTeam, false);
                        }
                        missedPenalties.push({ team: fouledTeam.displayName, player: penaltyTaker.name, minute: displayMinute })
                    }
                }

                if (isSentOff) {
                    //Replaces goalkeeper if they are sent off
                    let foulingTeam, teamKey;
                    if (minute % 2 === 0) {
                        foulingTeam = team1;
                        teamKey = 'team1';
                    } else {
                        foulingTeam = team2;
                        teamKey = 'team2';
                    }
                    const goalkeeper = foulingTeam.positionsData['Goalkeeper-0'];
                    //console.log('Goalkeeper:', goalkeeper, foulDetails.tacklerName, 'Fouling Player');
                    removePlayer(minute % 2 === 0 ? team1 : team2, foulDetails.tacklerName);
                    if (goalkeeper.name === foulDetails.tacklerName && foulingTeam) {
                        console.log(goalkeeper.name, '(Goalkeeper) was sent off has to be replaced');
                        const replaceDetails = await replaceGoalkeeper(foulingTeam, substitutionTracker[teamKey].length, maxSubstitutions);
                        const playerToBeSubbed = replaceDetails.bestPlayerInfo;
                        const subGoalkeeper = replaceDetails.subGoalkeeper;
                        console.log('Player to be subbed', playerToBeSubbed, 'subGoalkeeper', subGoalkeeper);
                        if (playerToBeSubbed && subGoalkeeper) {
                            substitutionTracker[teamKey].push({ key: 'Goalkeeper-0', player: playerToBeSubbed });
                            ratings[subGoalkeeper.name] = { team: foulingTeam.displayName, rating: 6.0 };
                            substitutions[teamKey].push({ substitutedPlayer: playerToBeSubbed.name, substitute: subGoalkeeper.name, minute: displayMinute });
                        }
                    }

                    const updatedEffectiveness = await calculateTeamEffectiveness();
                    team1Effectiveness = updatedEffectiveness.team1Effectiveness;
                    team2Effectiveness = updatedEffectiveness.team2Effectiveness;
                    team1ScoreProb = updatedEffectiveness.team1ScoreProb;
                    team2ScoreProb = updatedEffectiveness.team2ScoreProb;
                }
            } else {
                const randomValue = Math.random();
                const totalProbability = team1ScoreProb + team2ScoreProb;

                // Pre-determine the scorer and assister for both teams
                const { scorer: scorer1, assister: assister1 } = determineGoalScorer(team1, team2);
                const { scorer: scorer2, assister: assister2 } = determineGoalScorer(team2, team1);

                if (randomValue < (team1ScoreProb + 0.05) / (totalProbability * 35)) {
                    team1Goals++;

                    if (!scorer1.includes('o.g.')) {
                        // Update ratings for scorer1
                        ratings[scorer1].rating = Math.min(10, ratings[scorer1].rating + 2);
                        updateShots(scorer1, team1.displayName, true);
                    }

                    // Update ratings for assister1
                    if (assister1) {
                        ratings[assister1].rating = Math.min(10, ratings[assister1].rating + 1.5);
                    }

                    const goalkeeper = team2.positionsData['Goalkeeper-0'].name;
                    ratings[goalkeeper].rating = Math.max(0, ratings[goalkeeper].rating - 0.5);
                    goalScorers.push({ team: team1.displayName, name: scorer1, assister: assister1, minute: displayMinute });
                    //console.log(`${scorer1} has scored for ${team1.displayName} at ${displayMinute}' ${team1Goals}-${team2Goals}!`);

                } else if (randomValue < (team1ScoreProb + team2ScoreProb + 0.1) / (totalProbability * 35)) {
                    team2Goals++;

                    if (!scorer2.includes('o.g.')) {
                        // Update ratings for scorer2
                        ratings[scorer2].rating = Math.min(10, ratings[scorer2].rating + 1.5);
                        updateShots(scorer2, team2.displayName, true);
                    } else {
                        // Handle own goal scenario
                        const ownScorer = scorer2.replace(' (o.g.)', '');
                        ratings[ownScorer].rating = Math.max(0, ratings[ownScorer].rating - 0.8);
                    }

                    // Update ratings for assister2
                    if (assister2) {
                        ratings[assister2].rating = Math.min(10, ratings[assister2].rating + 1);
                    }

                    const goalkeeper = team1.positionsData['Goalkeeper-0'].name;
                    ratings[goalkeeper].rating = Math.max(0, ratings[goalkeeper].rating - 0.5);
                    goalScorers.push({ team: team2.displayName, name: scorer2, assister: assister2, minute: displayMinute });
                    //console.log(`${scorer2} has scored for ${team2.displayName} at ${displayMinute}' ${team1Goals}-${team2Goals}!`);
                }

                const team1Goalkeeper = team1.positionsData['Goalkeeper-0'].name;
                const team2Goalkeeper = team2.positionsData['Goalkeeper-0'].name;

                const randomMultiplier = (Math.random() * 8) + 10;
                const shotOnTargetChance = Math.random();
                const randomOnTargetChance = (Math.random() * 0.4) + 0.2;

                // Update goalkeeper ratings based on randomValue
                if (randomValue >= team1ScoreProb / (totalProbability * 35) && randomValue < randomMultiplier * (team1ScoreProb / (totalProbability * 35))) {
                    //console.log(`${team2Goalkeeper} saved a shot!`);
                    if (shotOnTargetChance < randomOnTargetChance) {
                        ratings[team2Goalkeeper].rating = Math.min(10, ratings[team2Goalkeeper].rating + 0.3);
                        updateShots(scorer1, team1.displayName, true);
                        //Calculates shot as save for goalkeeper
                        updateShots(team2Goalkeeper, team2.displayName, false, 'save');
                        getRatingForShotOnTarget(scorer1);
                    } else {
                        updateShots(scorer1, team1.displayName, false);
                        getRatingForShot(scorer1);
                    }
                }

                if (randomValue < ((team1ScoreProb + team2ScoreProb + 0.1) / (totalProbability * 35)) && randomValue < randomMultiplier * ((team1ScoreProb + team2ScoreProb + 0.1) / (totalProbability * 35))) {
                    if (shotOnTargetChance < randomOnTargetChance) {
                        ratings[team1Goalkeeper].rating = Math.min(10, ratings[team1Goalkeeper].rating + 0.3);
                        updateShots(scorer2, team2.displayName, true);
                        //console.log(`${team1Goalkeeper} saved a shot!`);
                        updateShots(team1Goalkeeper, team1.displayName, false, 'save');
                        getRatingForShotOnTarget(scorer2);
                    } else {
                        updateShots(scorer2, team2.displayName, false);
                        getRatingForShot(scorer2);
                    }

                }
            }
        }
    };
    let isOver = false;
    const stoppageTimeFirstHalf = Math.floor(Math.random() * 10) + 1;
    const stoppageTimeSecondHalf = Math.floor(Math.random() * 10) + 1;

    await playMinutes(1, 45, stoppageTimeFirstHalf);
    await playMinutes(46, 90, stoppageTimeSecondHalf);

    if (team1Goals === team2Goals && isCup) {
        // Extra time
        console.log(`Scores tied, extra time! ${team1Goals}-${team2Goals}`);
        const stoppageTimeFirstHalfET = Math.floor(Math.random() * 4) + 1;
        const stoppageTimeSecondHalfET = Math.floor(Math.random() * 4) + 1;
        await playMinutes(91, 105, stoppageTimeFirstHalfET);
        await playMinutes(106, 120, stoppageTimeSecondHalfET);

        if (team1Goals === team2Goals) {
            // Penalty shootout
            console.log(`Scores tied, penalty shootout!! ${team1Goals}-${team2Goals}`);
            penalties = await simulatePenalties(team1, team2);
            //console.log(penalties);
            isOver = true;
        } else {
            isOver = true;
        }
    } else {
        isOver = true;
    }
    if (isOver) {
        reinstateRemovedPlayers();
        //console.log(team1.displayName, team1Goals + '-' + team2Goals, team2.displayName);

        if (team1Goals === 0) {
            const goalkeeper = team2.positionsData['Goalkeeper-0'].name;
            ratings[goalkeeper].rating = Math.min(10, ratings[goalkeeper].rating + 2);
            Object.keys(team2.positionsData).filter(playerKey => playerKey.includes('Defender') || playerKey.includes('dMidfielder'))
                .forEach(key => {
                    const playerName = team2.positionsData[key].name;
                    ratings[playerName].rating = Math.min(10, ratings[playerName].rating + 1.5);
                });
        } else if (team2Goals === 0) {
            const goalkeeper = team1.positionsData['Goalkeeper-0'].name;
            ratings[goalkeeper].rating = Math.min(10, ratings[goalkeeper].rating + 2);
            Object.keys(team1.positionsData).filter(playerKey => playerKey.includes('Defender') || playerKey.includes('dMidfielder'))
                .forEach(key => {
                    const playerName = team1.positionsData[key].name;
                    ratings[playerName].rating = Math.min(10, ratings[playerName].rating + 1.5);
                });
        }

        const winningTeam = team1Goals > team2Goals ? team1 : team2Goals > team1Goals ? team2 : 'draw';
        const teamKey = winningTeam === team1 ? 'team1' : 'team2';
        const losingTeam = winningTeam === team1 ? team2 : team1;

        if (winningTeam !== 'draw') {
            //If team wins, midfielders get +2, defenders and goalkeeper get +1, forwards get +1.5,
            //console.log('Winning team:', winningTeam);
            Object.keys(winningTeam.positionsData).forEach(key => {
                const playerName = winningTeam.positionsData[key].name;
                ratings[playerName].rating = Math.min(10, ratings[playerName].rating + 0.5);
            });

            substitutions[teamKey].forEach(substitution => {
                const minute = toString(substitution.minute).includes('+') ? 89 : substitution.minute;
                const minutesPlayed = 90 - minute; // Assuming a 90-minute match
                if (minutesPlayed > 10) { // Apply boost only if they played at least 10 minutes
                    ratings[substitution.substitute].rating = Math.min(10, ratings[substitution.substitute].rating + 0.5);
                }
            });

            //midfielders get a random boost from 0.5 to 2;
            Object.keys(losingTeam.positionsData).forEach(key => {
                const playerName = losingTeam.positionsData[key].name;
                if (key.includes('Midfielder') && !key.startsWith('a')) {
                    const randomBoost = (Math.random() + 0.5).toFixed(2);
                    ratings[playerName].rating = Math.min(10, ratings[playerName].rating + parseInt(randomBoost));
                }

                if (key.includes('aMidfielder') || key.includes('Striker')) {
                    const randomNerf = Math.random().toFixed(2);
                    ratings[playerName].rating = Math.max(0, ratings[playerName].rating - parseInt(randomNerf));
                }
            });
        }

        const calculateManOfTheMatch = (ratings, winningTeam, losingTeam, winningTeamGoals, losingTeamGoals) => {
            let topPlayers = [];
            let highestRating = 0;
            let playerRatings = ratings;
            const isDraw = winningTeamGoals === losingTeamGoals;
            if (!isDraw) {
                playerRatings = Object.fromEntries(
                    Object.entries(playerRatings).filter(([playerName, playerData]) => playerData.team === winningTeam.displayName)
                );
            }

            for (const player in playerRatings) {
                const playerRating = playerRatings[player].rating;

                if (playerRating > highestRating) {
                    topPlayers = [player];  // Reset topPlayers array
                    highestRating = playerRating;
                } else if (playerRating === highestRating) {
                    topPlayers.push(player);  // Add to topPlayers if they have the same rating
                }
            }

            if (losingTeam !== 'draw') {
                const goalDifference = winningTeamGoals - losingTeamGoals;

                const losingGoalkeeper = losingTeam.positionsData['Goalkeeper-0'].name;

                if (goalDifference === 0 && losingGoalkeeper && playerRatings[losingGoalkeeper].rating >= 9.5) {
                    const twoGoalScorerExists = goalScorers.some(scorer => goalScorers.filter(g => g.name === scorer.name).length >= 2);

                    if (!twoGoalScorerExists) {
                        return {
                            player: losingGoalkeeper,
                            rating: playerRatings[losingGoalkeeper].rating,
                            team: playerRatings[losingGoalkeeper].name
                        }
                    }
                }
            }

            if (topPlayers.length === 1) {
                const player = topPlayers[0];
                return { player, rating: playerRatings[player].rating, team: playerRatings[player].team };
            }

            let playerGoals = {};
            goalScorers.forEach(goal => {
                const scorer = goal.name;
                if (!playerGoals[scorer] && !scorer.includes('o.g.')) {
                    playerGoals[scorer] = 1;
                } else {
                    playerGoals[scorer]++;
                }
            });

            let maxGoals = 0;
            let topGoalScorers = [];

            topPlayers.forEach(player => {
                const goals = playerGoals[player] || 0;
                if (goals > maxGoals) {
                    maxGoals = goals;
                    topGoalScorers = [player];  // Reset topGoalScorers
                } else if (goals === maxGoals) {
                    topGoalScorers.push(player);  // Add if tied in goals
                }
            });

            if (topGoalScorers.length === 1) {
                const player = topGoalScorers[0];
                return { player, rating: playerRatings[player].rating, team: playerRatings[player].team };
            }

            let playerAssists = {};
            goalScorers.forEach(goal => {
                const assister = goal.assister;
                if (assister) {
                    if (!playerAssists[assister] && assister !== '') {
                        playerAssists[assister] = 1;
                    } else {
                        playerAssists[assister]++;
                    }
                }
            });

            let maxAssists = 0;
            let topAssisters = [];

            topGoalScorers.forEach(player => {
                const assists = playerAssists[player] || 0;
                if (assists > maxAssists) {
                    maxAssists = assists;
                    topAssisters = [player];  // Reset topAssisters
                } else if (assists === maxAssists) {
                    topAssisters.push(player);  // Add if tied in assists
                }
            });

            // Return the final Man of the Match (if tied, return the first one)
            const manOfTheMatch = topAssisters[0] || topGoalScorers[0] || topPlayers[0];
            return { player: manOfTheMatch, rating: playerRatings[manOfTheMatch].rating, team: playerRatings[manOfTheMatch].team };
        };

        const winningTeamGoals = team1Goals > team2Goals ? team1Goals : team1Goals < team2Goals ? team2Goals : 'draw';
        const losingTeamGoals = winningTeamGoals === team1Goals ? team2Goals : winningTeamGoals === team2Goals ? team1Goals : 'draw';

        const manOfTheMatch = calculateManOfTheMatch(ratings, winningTeam, losingTeam, winningTeamGoals, losingTeamGoals);

        const results = {
            team1: team1, team1Goals,
            team2: team2, team2Goals,
            fouls, yellowCards, redCards,
            goalScorers, substitutions, penalties, missedPenalties,
            ratings, manOfTheMatch, shots
        };

        return results;
    }
}

export { simulateMatch, assignCaptain };