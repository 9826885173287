import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PaypalService from './resources/openai/PaypalService';
import './Payment.css';

const Payment = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [subscriptionID, setSubscriptionID] = useState(null);

    const handleSubscriptionSuccess = (subscriptionID) => {
        setSubscriptionID(subscriptionID);
    };

    return (
        <div className="payment-container">
            <h2>Payment</h2>
            {!subscriptionID ? (
                <PaypalService token={token} onSubscriptionSuccess={handleSubscriptionSuccess} />
            ) : (
                <div className="payment-confirmation">
                    <h3>Subscription Successful!</h3>
                    <p>Your subscription ID is: {subscriptionID}</p>
                    <button onClick={() => navigate('/')}>Go to Homepage</button>
                </div>
            )}
        </div>
    )
}

export default Payment;
