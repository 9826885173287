import { getStatForPosition, options } from "../CustomFormation";
import { generatePositionName } from "../Formations";

const positionParams = {
    Striker: 'strikers',
    aMidfielder: 'aMidfielders',
    Midfielder: 'midfielders',
    dMidfielder: 'dMidfielders',
    Defender: 'defenders'
  };

const handleFormationSelect = (selectedFormation) => {
    const [defenders, dMidfielders, midfielders, aMidfielders, strikers] = selectedFormation.split('-').map(Number);
    const formation = { defenders, dMidfielders, midfielders, aMidfielders, strikers };
    return formation;
};

const generatePositions = (formation, positionType, numPlayers) => {
    return Array.from({ length: numPlayers }, (_, index) => {
        const { originalName, modifiedName } = generatePositionName(formation, positionType, index);
        const positionKey = `${positionType}-${index}`; // Unique position key for the player

        return { positionKey, originalName, modifiedName };
    });
};

const getPositionGroups = (formationObject) => {
    const positionGroups = [
        { type: 'Striker', positions: generatePositions(formationObject, 'Striker', formationObject.strikers) },
        { type: 'aMidfielder', positions: generatePositions(formationObject, 'aMidfielder', formationObject.aMidfielders) },
        { type: 'Midfielder', positions: generatePositions(formationObject, 'Midfielder', formationObject.midfielders) },
        { type: 'dMidfielder', positions: generatePositions(formationObject, 'dMidfielder', formationObject.dMidfielders) },
        { type: 'Defender', positions: generatePositions(formationObject, 'Defender', formationObject.defenders) },
        { type: 'Goalkeeper', positions: generatePositions(formationObject, 'Goalkeeper', 1) }
    ];

    return positionGroups;
};

const getBenchPositions = (formationObject) => {
    const benchPositions = [];
    benchPositions.push('GK');

    if (formationObject.strikers > 0) {
        benchPositions.push('ST');
        if (formationObject.strikers === 2) {
            benchPositions.push('ST')
        } else if (formationObject.strikers === 3) {
            benchPositions.push('LW', 'RW');
        }
    }
    if (formationObject.dMidfielders > 0) {
        benchPositions.push('CDM');
        if (formationObject.dMidfielders > 1) {
            benchPositions.push('CDM');
        }
    }
    if (formationObject.aMidfielders > 0) {
        benchPositions.push('CAM');
        if (formationObject.aMidfielders === 2) {
            benchPositions.push('CAM')
        } else if (formationObject.aMidfielders === 3) {
            benchPositions.push('LW', 'RW');
        }
    }

    if (formationObject.midfielders > 0) {
        benchPositions.push('CM');
        if (formationObject.midfielders > 1) {
            benchPositions.push('CM')
        }
        if (formationObject.midfielders === 3) {
            benchPositions.push('CM')
        } else if (formationObject.midfielders === 4) {
            benchPositions.push('LM', 'RM');
        } else if (formationObject.midfielders === 5) {
            benchPositions.push('LWB', 'RWB');
        }
    }

    if (formationObject.defenders > 0) {
        benchPositions.push('CB');
        if (formationObject.defenders > 2) {
            benchPositions.push('CB');
        }
        if (formationObject.defenders > 3) {
            benchPositions.push('LB', 'RB');
        }
    }

    if (benchPositions.length > 10) {
        if (formationObject.midfielders > formationObject.defenders && formationObject.midfielders >= 4) {
            const cmIndex = benchPositions.indexOf('CM');
            if (cmIndex !== -1) {
                benchPositions.splice(cmIndex, 1);
            }
        } else if (formationObject.defenders > formationObject.midfielders && formationObject.defenders >= 4) {
            // Remove a single 'CB' if there are more than 4 defenders and more defenders than midfielders
            const cbIndex = benchPositions.indexOf('CB');
            if (cbIndex !== -1) {
                benchPositions.splice(cbIndex, 1);
            }
        } else if (formationObject.defenders === 4 && formationObject.midfielders === 4) {
            // If both defenders and midfielders are 4, remove a 'CB'
            const cbIndex = benchPositions.indexOf('CB');
            if (cbIndex !== -1) {
                benchPositions.splice(cbIndex, 1);
            }
        }
    }

    return benchPositions;
}

/**
 * Gets bench players from team that match positions in formation
 * @param {*} players All players in team
 * @param {*} usedPlayers players that already are in starting XI
 * @param {*} benchPositions bench positions based on the formation
 * @returns 
 */
const determineBenchPlayers = (players, usedPlayers, benchPositions) => {
    const bench = [], reserves = [];
    benchPositions.forEach(position => {
        let bestPlayer = null;
        let bestPlayerScore = -1;

        players.forEach(player => {
            if (!usedPlayers.has(player.id)) {
                const score = getStatForPosition(player, position);
                //console.log(`Score for ${player.name} in ${position}`, score);
                if (score > bestPlayerScore) {
                    bestPlayer = player;
                    bestPlayerScore = score;
                }
            }
        });

        if (bestPlayer) {
            bestPlayer.type = 'bench';
            bench.push(bestPlayer);
            usedPlayers.add(bestPlayer.id);
        }
    });

    //Adds remaining players to reserves
    players.forEach(player => {
        if (!usedPlayers.has(player.id)) {
            player.type = 'reserves';
            reserves.push((player))
        }
    });

    return { bench, reserves }
}

const findBestFormation = (oldQueue, oldBench, oldReserves, formation = null) => {
    const players = [...oldQueue, ...oldBench, ...oldReserves];
    let formationsWithScores = [];
    const swapCount = {};

    options.forEach(formation => {
        const formationObject = handleFormationSelect(formation.value);

        const positionGroups = getPositionGroups(formationObject);

        let usedPlayers = new Set();
        let totalScore = 0;
        let currentPlayerPositions = {};
        let allPossibleAssignments = [];

        oldQueue.forEach(player => {
            positionGroups.forEach(group => {
                group.positions.forEach(({ modifiedName }) => {
                    const score = getStatForPosition(player, modifiedName);

                    if (score > 0 && !usedPlayers.has(player.id)) {
                        allPossibleAssignments.push({
                            position: modifiedName,
                            player,
                            score,
                        });
                    }
                });
            });
        });

        // Step 3: Sort all possible assignments by score (highest first)
        allPossibleAssignments.sort((a, b) => b.score - a.score);
        //console.log(`All possible assignments for ${formation.label}:`, allPossibleAssignments);

        // Step 4: Assign the best players to the best positions globally
        let assignedPositions = {};
        allPossibleAssignments.forEach(({ position, player, score }) => {
            if (!usedPlayers.has(player.id) && !assignedPositions[position]) {
                assignedPositions[position] = { player, score };
                usedPlayers.add(player.id);
                totalScore += score;
            }
        });

        //console.log('assigned Positions:', assignedPositions);

        // Step 5: Fill currentPlayerPositions with the best assignments
        Object.keys(assignedPositions).forEach(position => {
            currentPlayerPositions[position] = assignedPositions[position].player;
        });

        //console.log(`Current player positions for ${formation.label}`, currentPlayerPositions);

        //Handle players with multiple positions by finding better fits if needed
        let emptyPositions = Object.keys(currentPlayerPositions).length < 11;
        while (emptyPositions) {
            let filledPositions = { ...currentPlayerPositions };
            Object.entries(filledPositions).forEach(([position, player]) => {
                positionGroups.forEach(group => {
                    if (swapCount[player.id] < 1) {
                        group.positions.forEach(({ modifiedName }) => {
                            if (!currentPlayerPositions[modifiedName]) {
                                const newScore = getStatForPosition(player, modifiedName);
                                let betterReplacementExists = false;

                                for (let i = 0; i < players.length; i++) {
                                    const benchOrReservePlayer = players[i];

                                    if (benchOrReservePlayer.id !== player.id) {  // Only consider players not yet used
                                        const benchOrReserveScore = getStatForPosition(benchOrReservePlayer, modifiedName);

                                        if (benchOrReserveScore > newScore) {
                                            betterReplacementExists = true;
                                            break;  // Break out of the loop if a better replacement exists
                                        }
                                    }
                                }

                                console.log(`Is there a better replacement for ${modifiedName}?: ${betterReplacementExists}`);
                                if (!betterReplacementExists) { // Move player if they perform better in another position
                                    if (formation.label === '4-2-2') {
                                        console.log(`${player.name} will swap to ${modifiedName} with score: ${newScore}`);
                                    }
                                    currentPlayerPositions[modifiedName] = player;
                                    delete currentPlayerPositions[position]; // Free up the original position
                                    swapCount[player.id]++;
                                    ///console.log('Replaced:', currentPlayerPositions);
                                }
                            }
                        });
                    }
                });
            });

            positionGroups.forEach(group => {
                group.positions.forEach(({ modifiedName }) => {
                    if (!currentPlayerPositions[modifiedName]) {
                        let bestPlayer = null;
                        let bestPlayerScore = -1;

                        players.forEach(player => {
                            if (!usedPlayers.has(player.id)) {
                                const score = getStatForPosition(player, modifiedName);
                                //console.log(`${player.name} score for ${modifiedName}: ${score}`);
                                if (score > bestPlayerScore) {
                                    bestPlayer = player;
                                    bestPlayerScore = score;
                                }
                            }
                        });

                        if (bestPlayer) {
                            bestPlayer.type = 'queue';
                            currentPlayerPositions[modifiedName] = bestPlayer;
                            totalScore += bestPlayerScore;
                            usedPlayers.add(bestPlayer.id);
                        }
                    }
                });
            });

            emptyPositions = Object.keys(currentPlayerPositions).length < 11;
        }

        // Compare score to find the best formation
        formationsWithScores.push({
            formationObject: formationObject,
            formation: formation.label,
            positionNames: positionGroups
                .map(group => group.positions.map(position => position.modifiedName)) // Extract modifiedName from each position
                .flat(),
            playerPositions: currentPlayerPositions,
            score: totalScore
        });
    });

    formationsWithScores.sort((a, b) => b.score - a.score);
    // Sort formations by score, highest first
    //console.log('Formations with scores', formationsWithScores);
    const bestFormation = formation ? formationsWithScores.find(f => f.formation === formation) : formationsWithScores[0];
    //console.log('Best Formation:', bestFormation);
    const usedPlayers = new Set(Object.values(bestFormation.playerPositions).map(player => player.id));

    const benchPositions = getBenchPositions(bestFormation.formationObject);
    //console.log('Bench positions:', benchPositions);

    const { bench, reserves } = determineBenchPlayers(players, usedPlayers, benchPositions);

    const positionNames = bestFormation.positionNames;

    const bestTeam = {
        bestFormation,
        bench,
        reserves,
        positionNames
    };
    // Return the highest score object
    return bestTeam;
};

const createPositionsData = (positionNames, currentPlayers, formation) => {
    const getPositionType = (positionName) => {
        if (positionName.includes('B') && (!positionName.includes('WB'))) return 'Defender';
        if (positionName.includes('CM') || positionName.includes('LM') || positionName.includes('RM') || positionName.includes('WB')) return 'Midfielder';
        if (positionName.endsWith('AM')) return 'aMidfielder';
        if (positionName.includes('S') || positionName.includes('W')) return 'Striker';
        if (positionName.endsWith('DM')) return 'dMidfielder';
    }

    const updatedPositions = {};
    for (let i = 0; i < positionNames.length; i++) {
        const player = currentPlayers[positionNames[i]];
        const position = positionNames[i];

        if (positionNames.includes(position) && player) {
            //console.log('Current position:', positionNames[i]);
            if (position === 'GK') {
                updatedPositions['Goalkeeper-0'] = player;
                player.currentPosition = { key: 'Goalkeeper-0', name: position };
            } else {
                const param = positionParams[getPositionType(position)]; //formation variable
                for (let j = 0; j < formation[param]; j++) {
                    const positionKey = `${getPositionType(position)}-${j}`;
                    if (!updatedPositions[positionKey]) {
                        updatedPositions[positionKey] = player;
                        player.currentPosition = { key: positionKey, name: position };
                        //console.log(player.name, 'has been added to', positionKey);
                        break;
                    }
                }
            }
        }
    }

    return updatedPositions;
}

export { createPositionsData, findBestFormation, getBenchPositions, determineBenchPlayers };