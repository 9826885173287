import React, { useState, useEffect } from 'react';
import defaultImage from '../resources/default_player.png';

const PlayerPhoto = ({ player }) => {
    // State to hold the current image URL, initialized to player.photo
    const [currentPhoto, setCurrentPhoto] = useState(player.photo);

    useEffect(() => {
        setCurrentPhoto(player.photo);  // Update the photo when player changes
    }, [player]); 
    
    // Handle image loading errors and update the image source accordingly
    const handleImageError = (event) => {
        const currentSrc = event.target.src;

        // Check and switch between the different image URLs
        if (currentSrc.includes('25_120.png')) {
            setCurrentPhoto(currentSrc.replace('25_120', '24_120'));  // Fallback to 24_120
        } else if (currentSrc.includes('24_120.png')) {
            setCurrentPhoto(currentSrc.replace('24_120', '23_120'));  // Fallback to 23_120
        } else if (currentSrc.includes('23_120.png')) {
            setCurrentPhoto(defaultImage);  // Fallback to default image
        }
    };

    return (
        <img
            src={currentPhoto}                     // Use the state variable as the image source
            onError={handleImageError}             // Handle fallback on error
            alt={player.name}
            className="player-photo"
        />
    );
};

export default PlayerPhoto;