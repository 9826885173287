import React from 'react';
import { useNavigate } from 'react-router-dom';
import './login-popup.css';

const LoginPopup = ({ onClose }) => {
    const navigate = useNavigate();
    
    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button className="close-btn" onClick={onClose}>X</button>
                <h2>You need to be logged in to save your formation</h2>
                <button className="popup-btn" onClick={() => navigate('/login')}>Login</button>
                <p>Don't have an account? Create one here!</p>
                <button className="popup-btn" onClick={() => navigate('/signup')}>Sign Up</button>
            </div>
        </div>
    );
}

export default LoginPopup;