import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import Cookies from 'js-cookie';
import { useNavigate, Link } from 'react-router-dom';
import './login.css';
import { IoArrowBack } from 'react-icons/io5';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState('');
    const [showResetEmail, setShowResetEmail] = useState(false);
    const [resetEmailSent, setResetEmailSent] = useState(false);
    const [resetErrors, setResetErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState('Reset Password');
    const [buttonColor, setButtonColor] = useState('#28a745');
    const [rememberMe, setRememberMe] = useState(false);
    const history = useNavigate();

    useEffect(() => {
        const savedUsername = localStorage.getItem('savedUsername');
        const savedRememberMe = localStorage.getItem('rememberMe') == 'true';
        if (savedRememberMe && savedUsername) {
            setUsername(savedUsername);
            setRememberMe(true);
        }
    }, []);
    const handleLogin = async () => {
        setErrors({});
        try {
            const response = await axios.post('https://footballcustomformation.com/php_backend/login.php', {
                username,
                password,
                rememberMe
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });

            const token = response.data.token;

            if (token) {
                //(Use this after real website) Cookies.set('token', token);
                localStorage.setItem('token', token);
                if (rememberMe) {
                    localStorage.setItem('token', token);
                    localStorage.setItem('savedUsername', username); // Optionally save username
                    localStorage.setItem('rememberMe', 'true');
                } else {
                    sessionStorage.setItem('token', token);
                    localStorage.removeItem('savedUsername');
                    localStorage.setItem('rememberMe', 'false');
                }
                onLogin(token);
                //console.log('Token:', token);
                history('/FIFAFormationCreator');
            } else {
                setErrors({ form: response.data.message });
            }
        } catch (error) {
            console.error('Login error', error);
            setErrors({ form: 'Login failed' })
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        handleLogin();
    };

    const handlePasswordReset = async () => {
        setIsLoading(true);
        setResetEmailSent(false);
        try {
            const response = await axios.post('https://footballcustomformation.com/php_backend/reset_password_request.php', { email });
            setResetErrors({});

            setTimeout(() => {
                setIsLoading(false);
                setResetEmailSent(true);
                setTimeout(() => {
                    setButtonText('Sent Again');
                    setButtonColor('#007bff');
                    setResetEmailSent(false); // Remove success message before showing loading circle again
                }, 10000); // 10 seconds delay
            }, 1000); // Minimum 1 second loading
        } catch (error) {
            setIsLoading(false);
            setResetErrors({ email: 'Failed to send reset email' });
        }
    }

    return (
        <div className="login-container">
            <button className="login-back-button" onClick={() => history('/')}>
                <IoArrowBack size={24} />
            </button>
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Email or Username</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyDown={handleKeyPress}
                    />
                    {errors.username && <span className="error">{errors.username}</span>}
                </div>
                <div className="form-group password">
                    <label>Password</label>
                    <div className="password-input">
                        <input
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyPress}
                        />
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="toggle-password"
                        >
                            {showPassword ? "Hide" : "Show"}
                        </button>
                    </div>
                    {errors.password && <span className="error">{errors.password}</span>}
                </div>
                <div className="remember-me-container">
                    <label className="remember-me-label">
                        <input 
                            type="checkbox"
                            checked={rememberMe}
                            onChange={() => setRememberMe(!rememberMe)}
                        />
                        Remember Me
                    </label>
                </div>
                <button className="login-button" type="submit">Login</button>
                {errors.form && <span className="error">{errors.form}</span>}
            </form>
            <div className="signup-link">
                <p>Don't have an account?</p> <Link to="/signup">Sign Up</Link>
            </div>
            <div className="forgot-password">
                <p onClick={() => setShowResetEmail(!showResetEmail)}>Forgot your password?</p>
                {showResetEmail && (
                    <div className="reset-password">
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button
                            onClick={handlePasswordReset}
                            style={{ backgroundColor: buttonColor }}>
                            {isLoading ? 'Loading...' : buttonText}
                        </button>
                        {resetEmailSent && <span className="success">Reset email sent!</span>}
                        {resetErrors.email && <span className="error">{resetErrors.email}</span>}
                    </div>
                )}
            </div>
        </div>
    );
}
export default Login;